import React from 'react'
import moment from 'src/local_Packages/moment/moment'
import Link from 'next/link'
import getConfig from 'next/config'

import { getHrefOfSlug } from '@Lib'

import * as Styled from './styled'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

interface INewsRecommendationsProps {
  lastArticles: INewsRecommendation[]
  title: string
}

interface INewsRecommendation {
  name: string
  publicationDate: string
  url: string
}

export const NewsRecommendations = ({ lastArticles, title }: INewsRecommendationsProps) => {
  const isNotNL = ['BE', 'FR'].indexOf(API_SITE) > -1

  if (!lastArticles) return

  return (
    <Styled.Wrapper isNotNL={isNotNL}>
      <Styled.Title isNotNL={isNotNL}>{title}</Styled.Title>

      {lastArticles.map((item, i) => {
        const date = moment(item.publicationDate)
        return (
          <Styled.ItemWrapper key={i}>
            <Link href={getHrefOfSlug(item.url)} as={item.url}>
              <Styled.RelatedLink title={item.name} isNotNL={isNotNL}>
                {item.name}
              </Styled.RelatedLink>
            </Link>
            <Styled.Time>{date.format('DD MMMM YYYY')}</Styled.Time>
          </Styled.ItemWrapper>
        )
      })}
    </Styled.Wrapper>
  )
}
