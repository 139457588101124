import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Link from 'next/link'
import Router from 'next/router'

import { RecipeActions } from '@Actions'
import { getRecipes, getRecipeSearchTerm, getRecipeFilters } from '@Redux/recipes/selectors'
import { getTranslator } from '@Redux/dictionary/selectors'
import { TranslationKeys } from '@Definitions'
import { IStore, IRecipeItem } from '@Interfaces'
import { Pagination } from '@Components'
import { getHrefOfSlug } from '@Lib'

import * as Styled from './styled'

interface IRecipesBlockProps {
  recipes: IRecipeItem[]
  totalRecipes: number
  currentPage: number
  totalPages: number
  slug: string
  query?: string
}

export const RecipesBlock = ({
  recipes,
  totalPages,
  totalRecipes,
  currentPage,
  slug,
  query,
}: IRecipesBlockProps) => {
  const t = useSelector((state: IStore) => getTranslator(state.dictionary))
  const searchTerm = useSelector((state: IStore) => getRecipeSearchTerm(state.recipes))
  const filtersFromStore = useSelector((state: IStore) => getRecipeFilters(state.recipes))
  const dispatch = useDispatch()

  const renderItem = (item: IRecipeItem, index: number) => {
    const { title, image, url } = item

    return (
      <Styled.Item key={index}>
        <Styled.ImageWrapper>
          <Link href={getHrefOfSlug(url)} as={url}>
            <a>
              <Styled.Image
                width="300"
                height="200"
                alt={title}
                src={image && image.desktopUrl}
                srcSet={image && image.mobileUrl && image.desktopUrl && `${image.mobileUrl} 1x, ${image.desktopUrl} 2x`}
              />
            </a>
          </Link>
        </Styled.ImageWrapper>
        <Styled.ContentWrapper>
          <h3>
            <Link href={getHrefOfSlug(url)} as={url}>
              <a>{title}</a>
            </Link>
          </h3>
        </Styled.ContentWrapper>
      </Styled.Item>
    )
  }

  const onPageChange = (page: number) => {
    // remove empty values
    const filtersString = filtersFromStore ? filtersFromStore.filter(val => !!val).join(',') : ''
    dispatch(RecipeActions.FetchData(searchTerm, filtersString, page))

    //TODO: vervangen van slug door iets uit de API.
    Router.push('/[slug]', `${slug}?pageNumber=${page}${filtersString ? `&filters=${filtersString}` : ``}${searchTerm ? `&searchTerm=${searchTerm}` : ``}`, { shallow: true });
  }

  const onInit = (page: number, filters: string, searchTerm: string) => {
    const pageNumber = page || 1
    dispatch(RecipeActions.SetSearchTerm(searchTerm))
    dispatch(RecipeActions.FetchData(searchTerm, filters, pageNumber))
  }

  useEffect(() => {
    // get items based on query
    if (query) {
      let params = new URLSearchParams(query)
      const paramPage = params ? params.get("pageNumber") : 0
      const page = paramPage ? parseInt(paramPage) : 0
      const paramFilters = params ? params.get("filters") : ''
      const filters = paramFilters ? paramFilters.toString() : ''
      const paramSearchTerm = params ? params.get("searchTerm") : ''
      const searchTerm = paramSearchTerm ? paramSearchTerm.toString() : ''
      if (page || filters || searchTerm) {
        onInit(page, filters, searchTerm)
      }
    }
  }, [])
  const recipesByPaging = useSelector((state: IStore) => getRecipes(state.recipes))

  let recipesToShow = recipes,
    totalToShow = totalRecipes,
    totalPagesToShow = totalPages,
    currentPageToShow = currentPage

  // TODO: aan Joey vragen waarom specifiek gekeken wordt naar searchTerm en filtersFromStore en commented line niet voldoende is
  // if (recipesByPaging && recipesByPaging.totalPages) {
  if (recipesByPaging && (recipesByPaging.currentPage > 1 || searchTerm !== '' || filtersFromStore.length > 0)) {
    recipesToShow = recipesByPaging.recipes
    totalToShow = recipesByPaging.totalRecipes
    totalPagesToShow = recipesByPaging.totalPages
    currentPageToShow = recipesByPaging.currentPage
  }

  return (
    <Styled.Wrapper>
      <h2>{`${totalToShow} ${
        totalRecipes === 1 ? t(TranslationKeys.ResultFound) : t(TranslationKeys.ResultsFound)
        }`}</h2>

      {recipesToShow && recipesToShow.length > 0 ?
        <Styled.Items>
          {recipesToShow && recipesToShow.map((item, i) => renderItem(item, i))}
        </Styled.Items> :
        <Styled.NoItems>
          {t(TranslationKeys.NoRecipeItems)}
        </Styled.NoItems>
      }

      <Pagination
        totalPage={totalPagesToShow}
        currentPage={currentPageToShow}
        onChange={onPageChange}
      />
    </Styled.Wrapper>
  )
}
