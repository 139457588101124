import styled, { AnyStyledComponent } from 'styled-components'
import { Grid } from '@material-ui/core'

export const Wrapper = styled.div``

export const AsideGrid: AnyStyledComponent = styled(Grid)`
  ${({ higher, theme }: any) => `
    ${theme.breakpoints.up('md')} {
      position: relative;
      top: ${higher === true ? '-270px' : '-100px'};
    }
  `}
`
