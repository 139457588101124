import styled from 'styled-components'
import theme from '@Themes'

const FooterBannerComponent = styled.section`
  background-color: #00322a;
  color: #f5d025;
  font-family: 'atkinson', serif;
  cursor: pointer;
  max-width: 1030px;
  margin: 0 auto;
  a {
    color: #f5d025;
    text-decoration: none;
  }
  ${theme.breakpoints.up('lg')} {
    background-color: #760c2f;
  }
  div {
    text-align: center;
    max-width: 1;
  }
  h2 {
    margin: 20px auto 10px;
    font-size: 40px;
    line-height: 40px;
    font-family: 'Playfair Display', serif;
  }
  h5 {
    font-size: 13px;
    max-width: 300px;
    margin: 0 auto;
    ${theme.breakpoints.up('lg')} {
      max-width: 500px;
      font-size: 16px;
    }
    &::before,
    &:after {
      content: '-';
      margin: 0 5px;
    }
  }
`
const FooterBannerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  max-width: 1030px;
  margin: 0 auto;
  ${theme.breakpoints.up('lg')} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const FooterBannerImageLeft = styled.div`
  display: none;
  ${theme.breakpoints.up('lg')} {
    display: block;
  }
`

export { FooterBannerComponent, FooterBannerContent, FooterBannerImageLeft }
