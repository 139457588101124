import styled from 'styled-components'

export const Footer = styled.footer`
  margin-top: 20px;
  padding-bottom: 20px;
`

export const InnerFooterWrapper = styled.div`
  ${({ theme }) => `
    border-top: 2px solid #e2dedc;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top:20px;
    width: 100%;

    ${theme.breakpoints.up('md')} {
      flex-direction: row;
      height: 200px;
    }	
	.imgOnlyDiv {
		inset: auto 20px 20px auto !important;
		position: fixed;
	}
  `}
`

export const LogoWrapper = styled.div`
  ${({ theme }) => `
    flex: 0 0 100%;

    ${theme.breakpoints.up('md')} {
      flex: 0 0 160px;
      height: 200px;
      max-width: 160px;
    }

    ${theme.breakpoints.up('lg')} {
      flex: 0 0 calc(100% / 3);
    }

    a {
      height: 110px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      cursor: pointer;
      width: 100%;

      ${theme.breakpoints.up('md')} {
        background-position: left;
      }
    }
  `}
`

export const LinkWrapper = styled.div`
  ${() => `
    display: flex;
    flex: 1;
    justify-content: space-around;
    padding-left: 30px;

    ul {
      display: inline-block;
      flex: 0 0 50%;
      list-style-type: none;
      margin: 0;
      padding: 0 10px;

      a {
        text-decoration: none;
        color: #6d5137;
        font-family: 'Melbourne', sans-serif;
        font-size: 16px;
        line-height: 24px;
      }

      li:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  `}
`

export const SocialWrapper = styled.div`
  ${({ theme }) => `
    color: #6d5137;
    font-family: 'Melbourne', sans-serif;
    font-size: 16px;
    height: 100%;
    line-height: 24px;
    padding: 0 10px;
    text-align: center;
    text-decoration: none;

    ${theme.breakpoints.up('md')} {
      text-align: left;
      width: 160px;
    }

    ${theme.breakpoints.up('lg')} {
      width: calc(100% / 3);
    }
  `}
`
export const SocialLink = styled.a`
  display: inline-block;
  margin-right: 8px;
`

export const CopyrightWrapper = styled.div`
  ${({ theme }) => `
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    text-align: center;

    p {
      margin: 0;
    }

    a {
      color: #6D5137;
      text-decoration: none;
    }

    ${theme.breakpoints.up('md')} {
      flex-direction: row;
      justify-content: space-between;
      height: 50px;
      text-align: left;
    }
  `}
`

export const ProfessionalsColumn = styled.div`
  p {
    text-align: left;
  }
`


