import styled, { css, AnyStyledComponent } from 'styled-components'

export const Wrapper: AnyStyledComponent = styled.section`
  ${({ isNotNL, theme }: any) => css`
    width: 100%;
    padding: 20px;
    border-radius: 3px;
    background-color: ${isNotNL ? theme.palette.secondary.light : '#e5f7ff'};
    color: ${isNotNL ? 'white' : '#1d83ad'};
  `}
`

export const Title: AnyStyledComponent = styled.h2`
  ${({ isNotNL }: any) => css`
    color: ${isNotNL ? 'white' : '#1D83AD'};
    font-family: 'Melbourne', sans-serif;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 22px;
    text-align: center;
  `}
`

export const RelatedLink: AnyStyledComponent = styled.a`
  ${({ isNotNL }: any) => css`
    color: ${isNotNL ? 'white' : '#1d83ad'};
    display: block;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    font-family: 'Melbourne', sans-serif;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  `}
`

export const ItemWrapper = styled.div`
  margin-bottom: 10px;
`

export const Time = styled.time`
  opacity: 0.5;
`
