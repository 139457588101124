import React from 'react'

import * as Styled from './styled'

interface IShadowHeadingProps {
  text: string
}

export const ShadowHeading: React.SFC<IShadowHeadingProps> = ({ text }) => {
  return (
    <Styled.Title component="div">
      <Styled.Title back component="span">
        {text}
      </Styled.Title>
      <Styled.Title front component="span">
        {text}
      </Styled.Title>
    </Styled.Title>
  )
}
