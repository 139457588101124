import styled, { AnyStyledComponent } from 'styled-components'

export const Logo: AnyStyledComponent = styled.a`
  ${({ theme, size, transparent, url }: any) => `
    max-width: 200px;
    width: ${size === 'small' ? '100px' : '100%'};
    height: ${size === 'small' ? '48px' : '100%'};
    background-color: #fff;
    background-image: ${url ? `url(${url})` : `url(/images/logo-brood.net.png)`};
    background-size: cover;
    background-position: center;
    cursor: pointer;

    ${theme.breakpoints.up('lg')} {
      background-color: ${transparent ? '#f3f3f3' : '#fff'};
      min-width: 150px;
      max-width: 220px;
      position: relative;
    }  
  `}
`
