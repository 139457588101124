import React from 'react'
import { Container, Grid } from '@material-ui/core'

import { IBlock } from '@Interfaces'
import * as Components from '@Components'

interface IBlockSplitterProps {
  blocks: IBlock[]
  pageKind?: string
  query?: string
  isCampaign?: boolean
}

export const BlockSplitter = ({ blocks, isCampaign, pageKind, query }: IBlockSplitterProps) => {
  return (
    <>
      {blocks &&
        blocks.map((block: any, index: number) => {
          if (block === null) return null
          const { objectKind, ...rest } = block
          let component = ''
          switch (objectKind) {
            case 'UMBTAGSANDSOCIALLINKSBLOCK':
              component = '' // is shown separately full width
              break
            case 'UMBCONTENTBLOCK':
              component = 'Text'
              break
            case 'UMBNEWSCONTENTBLOCK':
              component = 'NewsContent'
              break
            case 'UMBSIDEMENUBLOCK':
              component = 'Submenu'
              break
            case 'UMBHIGHLIGHTEDBLOCK':
              // if recipe detail page
              if (pageKind === 'UMBRECIPEDETAIL') {
                component = ''
              } else {
                component = 'HighlightBlockList'
              }
              break
            case 'UMBSIDEBARBLOCK':
            case 'UMBSIDEBARRECIPEIMAGEBLOCK':
              component = 'ColorBlock'
              break
            case '--TOBEIMPLEMENTED--SEARCHRESULTS':
              component = 'SearchResults'
              break
            case 'UMBINGREDIENTSBLOCK':
              component = 'Ingredients'
              break
            case 'UMBOVERVIEWNEWSARTICLESBLOCK':
              component = 'NewsList'
              break
            case '----TOBEIMPLEMENTED----':
              component = 'TwitterWidget'
              break
            case 'UMBFAQCATEGORIESBLOCK':
              component = 'FAQCategories'
              break
            case 'UMBFAQQUESTIONSBLOCK':
              component = 'FAQList'
              break
            case 'UMBNEWSLASTARTICLESBLOCK':
              component = 'NewsRecommendations'
              break
            case 'UMBBLOGLATESTARTICLESBLOCK':
              component = 'BlogRecommendations'
              break
            case 'UMBFAQRELATEDQUESTIONSBLOCK':
              component = 'FAQRelated'
              break
            case 'UMBRECIPEFILTERBLOCK':
              component = 'RecipeFilter'
              break
            case 'RECIPESBLOCK':
              component = 'RecipesBlock'
              break
            case 'UMBNUTRITIONALVALUEBLOCK':
              component = 'NutrientsBlock'
              break
            case 'UMBOVERVIEWBLOGPOSTBLOCK':
              component = 'BlogList'
              break
            case 'UMBPROFESIONALSNEWSLETTERBLOCK':
              component = 'NewsletterForm'
              break
            case 'UMBSEARCHPAGEBLOCK':
              component = 'SearchResults'
              break
            case 'UMBPROFESSIONALSCOLOREDBLOCKS':
              component = 'HighlightBlockListProf'
              break
            case 'UMBHEROSEARCHFAQBLOCK':
              component = 'FAQSearch'
              break
            case 'UMBHEROSEARCHBLOCK':
              component = 'HeroSearch'
              break
            case 'UMBHEROSEARCHRECIPEBLOCK':
              component = 'RecipeSearch'
              break
            case 'UMBHEROBLOCK':
              component = 'Banner'
              break
			case 'UMBHEROBLOCKMEDIA':
              component = 'Banner'
              break
            case 'UMBDOWNLOADBUTTONBLOCK':
              component = 'DownloadButton'
              break
            default:
              component = 'Unknown'
              break
          }

          const TheComponent: any = component ? (Components as any)[component] : null

          if (TheComponent) {
            if (
              !isCampaign ||
              [
                'UMBHEROBLOCK',
				'UMBHEROBLOCKMEDIA',
                'UMBHEROSEARCHBLOCK',
                'UMBHEROSEARCHFAQBLOCK',
                'UMBHEROSEARCHRECIPEBLOCK',
              ].indexOf(objectKind) > -1
            ) {
              return (
                <TheComponent
                  key={index}
                  {...rest}
                  query={query}
                  objectKind={objectKind}
                  pageKind={pageKind}
                />
              )
            }

            return (
              <Container key={index}>
                <Grid container>
                  <TheComponent
                    {...rest}
                    query={query}
                    objectKind={objectKind}
                    pageKind={pageKind}
                  />
                </Grid>
              </Container>
            )
          } else {
            return null
          }
        })}
    </>
  )
}
