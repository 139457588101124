import styled, { AnyStyledComponent } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => `
    margin-top: 20px;

    ${theme.breakpoints.up('md')} {
      column-count: 2;
      column-gap: 40px;
    }
  `}
`

export const BlockWrapper = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 2px;
`

export const InnerWrapper = styled.div`
  width: 100%;
  height: 100px;
  background-color: gray;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
  margin: 0px;
  padding: 0px;
  display: flex;
`

export const ImageWrapper: AnyStyledComponent = styled.div`
  width: 100px;
  min-width: 100px;
  height: 100%;
  background-color: #f0f0f0;
  margin-right: 10px;
  background-image: ${({ imageUrl }: any) => imageUrl || ''};
`

export const ContentWrapper = styled.div`
  ${({ theme }) => `
    padding: 10px;
    overflow: hidden;

    p {
      margin: 0px;
      padding: 0px;
    }

    h3 {
      margin: 0px;
      padding: 0px;

      a {
        font-size: 18px;
        line-height: 22px;
        color: ${theme.palette.primary.main}
        text-decoration: none;
      }
    }
  `}
`
