import styled, { AnyStyledComponent } from 'styled-components'

export const Wrapper = styled.section`
`

export const List = styled.ul`
  list-style: none outside;
  padding-left: 0;
`

export const ListItem: AnyStyledComponent = styled.li`
  ${({ theme, isNotNL }: any) => `
    a {
      color: ${isNotNL ? theme.palette.secondary.light : theme.palette.primary.light}
      display: block;
      font-family: 'Melbourne', sans-serif;
      font-size: 20px;
      line-height: 35px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  `}
`

export const Message = styled.div`
  background: #e5f7ff;
  border-radius: 6px;
  padding: 20px;

  h3 {
    color: #1d83ad;
  }
`
