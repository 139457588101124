import theme from '@Themes'
import styled from 'styled-components'

const HeroBannerComponent = styled.section`
  display: flex;
  color: #4d0e27;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  text-align: center;
  background-image: url('/images/landingpage/heroBannerBackground.webp');
  background-position: center;
  background-size: cover;
  font-family: 'atkinson', serif;

  h1 {
    font-size: 46px;
    line-height: 46px;
    font-family: 'Playfair Display', serif;
    ${theme.breakpoints.up('lg')} {
      font-size: 70px;
      line-height: 110px;
    }
  }
h2 {
    font-size: 32px;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 25px;
}
p {
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto 50px;
    ${theme.breakpoints.up('lg')} {
      max-width: 700px;
      font-size: 18px;
      margin: 0 auto 100px;
    }
  }
  span {
    font-size: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:after {
      content: '';
      width: 17px;
      height: 11px;
      display: block;
      background-image: url('/images/icons/arrow-down.png');
    }
  }
`
const HeroBannerImage = styled.div`
  max-width: 500px;
  margin: 50px auto -40px;
  padding: 20px;
  ${theme.breakpoints.up('lg')} {
    margin: 50px auto -60px;
    max-width: 600px;
  }
  img {
    width: 100%;
  }
`

export { HeroBannerComponent, HeroBannerImage }
