import React from 'react'
import Router from 'next/router'
import getConfig from 'next/config'
import { Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { IStore } from '@Interfaces'
import { TranslationKeys } from '@Definitions';
import { Pagination, SearchFilter } from '@Components'
import { getTranslator } from '@Redux/dictionary/selectors'
import { getSearchCategories, getGeneralSearchTerm } from '@Redux/general/selectors'

import { ISearchCategory } from '../../SearchFilter'
import * as Styled from './styled'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

export interface ISearchResults {
  results: ISearchItem[]
  categories: ISearchCategory[]
  query: string
  currentPage: number
  totalPages: number
  totalResults: number
}

export interface ISearchItem {
  title: string
  category: string
  result: string
  url: string
  seoDescription: string
}

export const SearchResults = (searchResults: ISearchResults) => {
  const t = useSelector((state: IStore) => getTranslator(state.dictionary))
  const searchCategories = useSelector((state: IStore) => getSearchCategories(state.general))
  const searchTerm = useSelector((state: IStore) => getGeneralSearchTerm(state.general))

  const handlePageChange = (page: number) => {
    const categories = searchCategories
      .filter((c: any) => c.active === true)  
      .map((c: any) => c.id)
      .join(',')

    const query = `?searchTerm=${searchTerm}&pageNumber=${page}&categories=${categories}`
    const as = `/${API_SITE === 'FR' ? 'recherche' : 'zoeken'}${query}`
    Router.push(`/search${query}`, as)
  }

  return (
    <Styled.Wrapper>
      <SearchFilter categories={searchCategories} resultsCategories={searchResults.categories} />
      <Styled.Inner>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Styled.Title component="h2" variant="h2" color="secondary">
              {searchResults.totalResults === 1
                ? `${searchResults.totalResults} ${t(TranslationKeys.ResultFound)}`
                : `${searchResults.totalResults} ${t(TranslationKeys.ResultsFound)} "${searchTerm}"`}
            </Styled.Title>
            <Styled.List>
              {searchResults.results &&
                searchResults.results.map((item: ISearchItem, i: any) => (
                  <Styled.ListItem key={i}>
                    <Styled.ListItemTitle component="h3" variant="h3" site={API_SITE}>
                      <a href={item.url} dangerouslySetInnerHTML={{ __html: item.title }}></a>
                    </Styled.ListItemTitle>
                    <Styled.ListItemText>
                      {item.category && (
                        <Styled.Category>{item.category}</Styled.Category>
                      )}
                      {item.seoDescription}
                    </Styled.ListItemText>
                    <Styled.ListItemQuote dangerouslySetInnerHTML={{ __html: item.result }} />
                  </Styled.ListItem>
                ))}
            </Styled.List>
            <Pagination
              totalPage={searchResults.totalPages}
              currentPage={searchResults.currentPage}
              onChange={handlePageChange}
            />
          </Grid>
        </Grid>
      </Styled.Inner>
    </Styled.Wrapper>
  )
}
