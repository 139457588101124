import React from 'react'
import Link from 'next/link'
import { getHrefOfSlug } from '@Lib'

interface ICustomLinkProps {
  url: string
  children: any
  target?: string
}

export const CustomLink = ({ url, children, target }: ICustomLinkProps) => {
  if (!url) return null

  const isExternalLink = url.indexOf('http') > -1 || target === '_blank'

  // if external link use a, else use NextLink
  if (isExternalLink) {
    return (
      <a href={url} target={target || '_self'} rel="nofollow noopener noreferrer">
        {children}
      </a>
    )
  }
  return (
    <Link href={getHrefOfSlug(url)} as={url} passHref>
      <a>{children}</a>
    </Link>
  )
}
