import React from 'react'
import getConfig from 'next/config'
import { useSelector } from 'react-redux'

import { IStore } from '@Interfaces'
import { TranslationKeys } from '@Definitions'
import { getTranslator } from '@Redux/dictionary/selectors'

import * as Styled from './styled'

interface INutrientItem {
  label: string
  value: string
}

interface INutrientsBlockProps {
  values: INutrientItem[]
}

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

export const NutrientsBlock = ({ values }: INutrientsBlockProps) => {
  const t = useSelector((state: IStore) => getTranslator(state.dictionary))
  const isNotNL = ['BE', 'FR'].indexOf(API_SITE) > -1

  const itemProps = [
    'calories',
    'proteinContent',
    'carbohydrateContent',
    'fatContent',
    'saturatedFatContent',
    'fiberContent',
  ]

  return (
    <Styled.Wrapper
      itemProp="nutrition"
      itemScope
      itemType="https://schema.org/NutritionInformation"
      isNotNL={isNotNL}
    >
      <Styled.Title isNotNL={isNotNL}>{t(TranslationKeys.NutrientsTitle)}</Styled.Title>
      <Styled.List isNotNL={isNotNL}>
        {values &&
          values.map((item: INutrientItem, i: number) => {
            return (
              <li key={i}>
                <Styled.Bold>{item.label}</Styled.Bold>
                <span itemProp={itemProps.length > i ? itemProps[i] : ''}>{item.value}</span>
              </li>
            )
          })}
      </Styled.List>
    </Styled.Wrapper>
  )
}
