import theme from '@Themes'
import styled, { css } from 'styled-components'

const CarouselComponent = styled.section`
  position: relative;
  max-width: 1030px;
  margin: 0 auto;
  height: 700px;
  background-color: #4d0e27;
  ${theme.breakpoints.up('lg')} {
    height: 450px;
  }
`

const CarouselItem = styled.article<{ currentSlide: boolean }>` 
  color: #f5ece0;
  text-align: center;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  h3 {
    font-size: 30px;
    line-height: 49px;
    ${theme.breakpoints.up('lg')} {
      white-space: nowrap;
    }
  }
`

const CarouselContent = styled.div<{ currentSlide: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  gap: 50px;
  opacity: 1;
  transition-duration: 1s;
  ${theme.breakpoints.up('lg')} {
    flex-direction: row;
  }
  ${({ currentSlide }) =>
    !currentSlide &&
    css`
      opacity: 0;
      transition-duration: 1s ease;
    `}
  p {
    max-width: 300px;
    text-align: left;
    font-size: 14px;
  }
`
const CarouselDual = styled.div`
  display: flex;
  gap: 20px;
`

const CarouselImage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;

  img {
    width: 100px;
    height: 100px;
    overflow: hidden;
    object-fit: fill;
  }
`

const CarouselVS = styled.span`
  font-weight: bold;
  font-size: 27px;
  position: relative;
  display: block;
  &::before,
  &:after {
    content: '';
    display: block;
    margin: 0 auto;
    height: 38px;
    width: 1px;
    background-color: #ffb8b8;
  }
`
const CarouselProcess = styled.div`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 20px;
`

const CarouselBol = styled.span<{ activeBol: boolean }>`
  width: 11px;
  height: 11px;
  border-radius: 50%;
  border: 1px solid #ffb8b8;
  cursor: pointer;
  ${({ activeBol }) =>
    activeBol &&
    css`
      background-color: #fff0e2;
    `}
`

export {
  CarouselComponent,
  CarouselItem,
  CarouselContent,
  CarouselImage,
  CarouselVS,
  CarouselDual,
  CarouselProcess,
  CarouselBol,
}
