import React from 'react'
import Link from 'next/link'
import getConfig from 'next/config'

import { getHrefOfSlug } from '@Lib'

import * as Styled from './styled'

const {
  publicRuntimeConfig: { API_SITE, IS_PROFESSIONAL },
} = getConfig()

interface ISubmenuProps {
  sidebarMenuTitle: string
  sidebarMenuSubTitle: string
  sidebarMenuSubTitleUrl?: string
  sidebarMenuItems: ISubmenuItemProp[]
  showMenuBlock: boolean
}

interface ISubmenuItemProp {
  name: string
  url: string
}

export const Submenu = ({
  sidebarMenuTitle,
  sidebarMenuSubTitle,
  sidebarMenuSubTitleUrl,
  sidebarMenuItems,
  showMenuBlock,
}: ISubmenuProps) => {
  const isNotNL = ['BE', 'FR'].indexOf(API_SITE) > -1
  const isProfessional = IS_PROFESSIONAL === 'true'

  return (
    <Styled.Wrapper isNotNL={isNotNL} isProfessional={isProfessional}>
      {sidebarMenuTitle && <Styled.Title>{sidebarMenuTitle}</Styled.Title>}
      {sidebarMenuSubTitle && sidebarMenuSubTitleUrl ? (
        <Link href={getHrefOfSlug(sidebarMenuSubTitleUrl)} as={sidebarMenuSubTitleUrl}>
          <Styled.SubTitle isLink isNotNL={isNotNL} isProfessional={isProfessional}>
            {sidebarMenuSubTitle}
          </Styled.SubTitle>
        </Link>
      ) : (
        <Styled.SubTitle>{sidebarMenuSubTitle}</Styled.SubTitle>
      )}
      {showMenuBlock && sidebarMenuItems && sidebarMenuItems.length > 0 && (
        <ul>
          {sidebarMenuItems.map((item: ISubmenuItemProp, i: number) => (
            <Styled.SubmenuItem key={i} isNotNL={isNotNL}>
              <Link href={getHrefOfSlug(item.url)} as={item.url}>
                <a target={item.url.indexOf('http') > -1 ? '_blank' : ''}>{item.name}</a>
              </Link>
            </Styled.SubmenuItem>
          ))}
        </ul>
      )}
    </Styled.Wrapper>
  )
}
