import styled, { css, AnyStyledComponent } from 'styled-components'

import { extraColors } from '@Themes'

export const Wrapper: AnyStyledComponent = styled.div`
  ${({ isNotNL, isProfessional }: any) => css`
    border-radius: 3px;
    background: ${isNotNL
      ? isProfessional
        ? extraColors.orange[0]
        : extraColors.yellow[0]
      : 'rgba(183, 0, 64, 0.9)'};
    color: white;
    padding-bottom: 20px;
    padding-top: 5px;
    margin-bottom: 20px;

    ul {
      list-style: none outside;
      margin: 0px;
      padding: 0px;
    }
  `}
`

export const SubmenuItem: AnyStyledComponent = styled.li`
  ${({ theme, isNotNL }: any) => `
    padding: 5px 20px 5px 20px;
    width: 100%;

    a {
      color: ${isNotNL ? 'white' : theme.palette.primary.contrastText}
      font-family: 'Melbourne', sans-serif;
      font-size: 18px;
      font-weight: 400;
      text-decoration: none;

      &:after {
        border-right: 2px solid ${isNotNL ? 'white' : theme.palette.primary.contrastText};
        border-top: 2px solid ${isNotNL ? 'white' : theme.palette.primary.contrastText};
        content: '';
        display: inline-block;
        height: 6px;
        margin-left: 10px;
        transition: margin 0.25s ease-in-out;
        transform: rotate(45deg);
        position: relative;
        top: -2px;
        width: 6px;
      }
    }

    &:hover {
      a {
        &:after {
          margin-left: 15px;
        }
      }
    }
  `}
`

export const Title = styled.div`
  font-size: 38px;
  color: white;
  font-family: 'Melbourne', sans-serif;
  font-weight: 400;
  line-height: 38px;
  margin: 0;
  padding: 15px 20px;
`

export const SubTitle: AnyStyledComponent = styled.div`
  font-size: 24px;
  color: white;
  font-family: 'Melbourne', sans-serif;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  padding: 0 20px 15px 20px;

  ${({ isLink }: any) =>
    isLink
      ? `
        cursor: pointer;
      `
      : ``}
`
