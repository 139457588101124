import React from 'react'
import getConfig from 'next/config'

import { extraColors } from '@Themes'

import * as Styled from './styled'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

export interface IHighlightBlockProfProps {
  title: string
  text: string
  link: string
  index: number
  isLastIndex: boolean
}

export const HighlightBlockProf = ({
  title,
  text,
  link,
  index,
  isLastIndex,
}: IHighlightBlockProfProps) => {
  const colors = ['#E97D44', '#DE5129', '#F3AD53']
  const altColors = [extraColors.gray[0], extraColors.gray[0], extraColors.gray[0]]
  const isNotNL = ['BE', 'FR'].indexOf(API_SITE) > -1

  return (
    <Styled.Wrapper
      site={API_SITE}
      color={isNotNL ? altColors[index] : colors[index]}
      textColor={isNotNL ? extraColors.gray[1] : '#ffffff'}
      noBorder={isLastIndex}
    >
      <Styled.ContentWrapper site={API_SITE}>
        {title && (
          <h4>
            <a href={link}>{title}</a>
          </h4>
        )}
        {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
      </Styled.ContentWrapper>
    </Styled.Wrapper>
  )
}
