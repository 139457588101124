import * as Styled from './styled'
import React, { useEffect, useState } from 'react'
import getConfig from 'next/config'
import { getHrefOfSlug } from '@Lib'
import Link from 'next/link'

import { INavigationItem, ISocial } from '@Interfaces'
import { SearchBox, SocialIcons } from '@Components'

interface IProps {
  primaryMenuItems: INavigationItem[]
  secondaryMenuItems: INavigationItem[]
  socialItems: ISocial[]
  hoverMenuItem: number
  otherLanguageUrl: string
  setActiveSubMenu: (index: number, hover: boolean, subMenu: boolean) => void
  activeSubMenu: number
  closeMenu: () => void
}

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

export const Menu = ({
  primaryMenuItems,
  secondaryMenuItems,
  socialItems,
  hoverMenuItem,
  activeSubMenu,
  otherLanguageUrl,
  setActiveSubMenu,
  closeMenu,
}: IProps) => {
  const [touch, setTouch] = useState(false)
  const [touchIndex, setTouchIndex] = useState(-1)
  const [langUrl, setLangUrl] = useState("")

  const onHandleTouch = (e: React.TouchEvent, index: number) => {
    e.preventDefault()
    // set touch to know if touch event
    setTouch(true)
    // set touch index after minor timeout, so click event is prevented on first touch
    setTimeout(() => setTouchIndex(index), 200)
    return false
  }

  const onHandleClick = (e: React.MouseEvent, index: number) => {
    // if touch event has been triggered
    if (touch) {
      // only follow link if touch index has been set (after 2nd tap, so we can first show submenu)
      if (touchIndex !== index) {
        e.preventDefault()
        return false
      }
    }
  }

  let langName: string = ""
  if (API_SITE === 'BE') {
    langName = 'Français'
  } else if (API_SITE === 'FR') {
    langName = 'Nederlands'
  }

  const setUrl = () => {
    if(typeof window !== "undefined")
    {
      let url = window.location.href;
      let queryIndex = url.indexOf("?");
      console.log(queryIndex);
      if(queryIndex !== -1)
      {
        let langQuery = otherLanguageUrl + url.substring(url.indexOf("?"))
        setLangUrl(langQuery);
      }else
      {
        setLangUrl(otherLanguageUrl);
      }
    }
  }

  useEffect(() => {
    setUrl();
  }, [])

  return (
    <>
      <Styled.MenuWrapper>
        <Styled.InnerMenu>
          <Styled.TopMenu site={API_SITE}>
            <SearchBox />
            <ul>
              {secondaryMenuItems &&
                secondaryMenuItems.map((item: INavigationItem, i: number) => (
                  <Styled.TopMenuItem
                    key={i}
                    active={false}
                    variant="top-menu-item"
                    site={API_SITE}
                    alternativeBackground={item.linkType === 'prof_switch'}
                  >
                    {item.linkType === 'external' || item.linkType === 'prof_switch' ? (
                      <a href={item.url} target={item.openInNewTab ? '_blank' : '_self'}>
                        {item.name}
                      </a>
                    ) : (
                      <Link href={getHrefOfSlug(item.url)} as={item.url}>
                        <a>{item.name}</a>
                      </Link>
                    )}
                  </Styled.TopMenuItem>
                ))}
              {otherLanguageUrl && langName && (
                <Styled.TopMenuItem
                  key={'language_switcher'}
                  active={false}
                  variant={langName}
                  site={API_SITE}
                >
                  <a href={langUrl}>{langName}</a>
                </Styled.TopMenuItem>
              )}
              <SocialIcons items={socialItems} />
            </ul>
          </Styled.TopMenu>
          <Styled.BottomMenu hover={activeSubMenu > -1} site={API_SITE}>
            <ul>
              {primaryMenuItems &&
                primaryMenuItems.map((item: INavigationItem, i: number) => (
                  <Styled.BottomMenuLink
                    site={API_SITE}
                    subItems={item.subItems && item.subItems.length > 0}
                    hover={item.subItems && item.subItems.length > 0 ? hoverMenuItem === i : false}
                    onMouseEnter={() =>
                      setActiveSubMenu(i, true, !!(item.subItems && item.subItems.length > 0))
                    }
                    onMouseLeave={() =>
                      setActiveSubMenu(
                        -1,
                        !(item.subItems && item.subItems.length > 0),
                        !(item.subItems && item.subItems.length > 0),
                      )
                    }
                    key={i}
                  >
                    {item.linkType === 'external' || item.linkType === 'prof_switch' ? (
                      <a href={item.url} target={item.openInNewTab ? '_blank' : '_self'}>
                        {item.name}
                      </a>
                    ) : (
                      <Link href={getHrefOfSlug(item.url)} as={item.url}>
                        <a
                          onTouchStart={e => onHandleTouch(e, i)}
                          onClick={e => onHandleClick(e, i)}
                        >
                          {item.name}
                        </a>
                      </Link>
                    )}
                  </Styled.BottomMenuLink>
                ))}
            </ul>
          </Styled.BottomMenu>
        </Styled.InnerMenu>
        <Styled.FoldMenuWrapper>
          {primaryMenuItems &&
            primaryMenuItems.map((item: INavigationItem, i: number) => {
              if (item.subItems && item.subItems.length > 0) {
                return (
                  <Styled.FoldMenuInnerWrapper
                    show={activeSubMenu === i}
                    onMouseLeave={() => setActiveSubMenu(-1, true, true)}
                    key={i}
                  >
                    <Styled.FoldMenuCenter>
                      <ul>
                        {item.subItems.map((subItem: INavigationItem, i2: number) => (
                          <li key={i2}>
                            {subItem.linkType === 'external' ||
                              subItem.linkType === 'prof_switch' ? (
                              <a
                                href={subItem.url}
                                target={subItem.openInNewTab ? '_blank' : '_self'}
                              >
                                {subItem.name}
                              </a>
                            ) : (
                              <Link href={getHrefOfSlug(subItem.url)} as={subItem.url}>
                                <a>{subItem.name}</a>
                              </Link>
                            )}
                          </li>
                        ))}
                      </ul>
                    </Styled.FoldMenuCenter>
                  </Styled.FoldMenuInnerWrapper>
                )
              }
            })}
        </Styled.FoldMenuWrapper>
      </Styled.MenuWrapper>
      <Styled.Shadow show={activeSubMenu > -1} onClick={() => closeMenu()} />
    </>
  )
}
