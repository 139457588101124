import styled from 'styled-components'

export const Wrapper = styled.div`
  border-radius: 3px;
  padding: 20px;
  width: 100%;
  max-width: 620px;

  iframe {
    border: none; 
    margin-bottom: 40px;
  }
`

export const Image = styled.img`
  margin-bottom: 14px;
`
