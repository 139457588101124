import React from 'react'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import * as Styled from './styled'

export const Select = (props: any): JSX.Element => {
  const { value, onChange, currentValue, ...rest } = props

  const currentValueId = currentValue ? currentValue.toString() : ''
  const selectedValue = value.find((option: any) => option.id.toString() === currentValueId)

  return (
    <Styled.Select
      fullWidth
      displayEmpty
      disableUnderline
      onChange={onChange}
      IconComponent={ExpandMoreIcon}
      renderValue={() => (selectedValue && selectedValue.name) || props.label}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          marginTop: '10px',
          horizontal: 'center',
        },
      }}
      value={currentValueId}
      {...rest}
    >
      {value &&
        value.map((item: any, index: number) => {
          return (
            <Styled.MenuItem key={index} value={item.id}>
              {item.name}
            </Styled.MenuItem>
          )
        })}
    </Styled.Select>
  )
}
