import styled, { AnyStyledComponent } from 'styled-components'
import {
  Button as MuiButton,
  OutlinedInput as MuiOutlinedInput,
  InputLabel,
  Typography,
} from '@material-ui/core'

export const Wrapper = styled.div`
  background: #fff;
  border-radius: 3px;
  padding: 20px;
  width: 100%;
  max-width: 620px;
  margin-bottom: 20px;
`
export const Title: AnyStyledComponent = styled(Typography)`
  color: #1d83ad;
  font-size: 28px;
  line-height: 22px;
  margin-bottom: 10px;
  font-weight: 400;
  font-family: 'Melbourne', sans-serif;
`

export const Intro = styled(Typography)`
  color: #727272;
  a {
    color: #727272;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  margin-bottom: 20px;
`

export const Label: AnyStyledComponent = styled(InputLabel)`
  color: #727272 !important;
  display: block;
  line-height: 1.4;
  padding-bottom: 3px;
  position: static;
  transform: none;
  width: 100%;
`

export const TextField: AnyStyledComponent = styled(MuiOutlinedInput)`
  background: #fff;
  border-radius: 3px;
  display: block;
  width: 100%;
  font-family: 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  input {
    background: #fff;
    box-sizing: border-box;
  }
  fieldset {
    border: solid 1px #1d83ad !important;
  }
`

export const Button: AnyStyledComponent = styled(MuiButton)`
  color: #fff;
  background-color: #1d83ad;
  border: 0;
  border-radius: 3px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  font-family: 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 14px;
  text-transform: none;

  &:hover {
    background-color: #1d83ad;
  }
`

export const SuccesMessage: AnyStyledComponent = styled.p`
  color: #727272;
  line-height: 1.4;
  position: static;
  transform: none;
  width: 100%;
`
