import styled, { AnyStyledComponent } from 'styled-components'
import { IconButton } from '@material-ui/core'

export const Wrapper = styled.header`
  width: 100%;

  .scrollmagic-pin-spacer {
    z-index: 1;
  }
`

export const MenuWrapper: AnyStyledComponent = styled.div`
  ${({ theme }) => `
    box-shadow: 0px 2px 22px 0px rgba(0, 0, 0, 0.1);
    height: 90px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    width: 100%;
    z-index: 1100;
    
    ${theme.breakpoints.up('lg')} {
      height: 115px;
      padding: 0;
      position: relative;
      z-index: 99999;
    }
  `}
`

export const DesktopMenu = styled.div`
  ${({ theme }) => `
  width: 100%;
  display: none;

  ${theme.breakpoints.up('lg')} {
      display: block;
  `}
`

export const MobileMenu = styled.div`
  ${({ theme }) => `
  display: none;

  ${theme.breakpoints.down('md')} {
      display: block;
  `}
`

export const HamburgerButton = styled(IconButton)`
  &:hover {
    background-color: transparent;
  }
`
export const HamburgerIcon = styled.span`
  background-image: url(/images/icons/hamburger.svg);
  background-size: 33px 28px;
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  height: 44px;
  width: 44px;
`
