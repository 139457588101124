import styled, { AnyStyledComponent } from 'styled-components'

export const Wrapper = styled.div`
  border-bottom: solid 1px #dedede;
  padding-bottom: 20px;
  margin-bottom: 20px;
`

export const Label = styled.span`
  color: #501210;
  display: inline-block;
  font-size: 22px;
  font-family: 'Melbourne', sans-serif;
  margin-right: 10px;
`

export const Category: AnyStyledComponent = styled.span`
  ${({ theme, active }: any) => `
    font-family: 'Melbourne', sans-serif;
    font-size: 22px;
    color: ${theme.palette.primary.main};
    position: relative;
    display: inline-block;
    a {
      text-decoration: none;
      height: 36px;
      line-height: 36px;
      border-radius: 18px;
      display: inline-block;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 9px;
      margin-right: 9px;

      ${
        active
          ? `
          background-color: ${theme.palette.primary.light};
          color: #fff;
          `
          : `color: ${theme.palette.primary.main};`
      }

      &:hover {
        background-color: #eee;
      }
    }
  `}
`
export const Amount: AnyStyledComponent = styled.span`
  ${({ theme, active }: any) => `
    position: absolute;
    top: 0px;
    right: 5px;
    display: inline-block;
    text-align: center;
    height: 18px;
    line-height: 18px;
    min-width: 18px;
    font-size: 12px;
    border-radius: 9px;

    ${
      active
        ? `
      background-color: ${theme.palette.primary.main};
      color: #fff;
    `
        : `
    color: #4d4d4d;
    `
    }
  `}
`
