import React from 'react'
import getConfig from 'next/config'

import * as Styled from './styled'

interface IFAQRelatedQuestions {
  relatedQuestions: IRelatedQuestion[]
  readMoreLink?: string
  readMoreLinkText?: string
  title?: string
}

interface IRelatedQuestion {
  name: string
  url: string
}

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

export const FAQRelated = (props: IFAQRelatedQuestions) => {
  const isNotNL = ['BE', 'FR'].indexOf(API_SITE) > -1

  return (
    <Styled.Wrapper isNotNL={isNotNL}>
      <Styled.Title isNotNL={isNotNL}>{props.title}</Styled.Title>
      {props && (
        <Styled.List>
          {props.relatedQuestions.map((question: IRelatedQuestion, i: number) => {
            return (
              <Styled.Item key={i} isNotNL={isNotNL}>
                <a href={question.url}>{question.name}</a>
              </Styled.Item>
            )
          })}
        </Styled.List>
      )}
      {props.readMoreLink && props.readMoreLinkText && (
        <Styled.AllQuestionsLink href={props.readMoreLink} isNotNL={isNotNL}>
          {props.readMoreLinkText} →
        </Styled.AllQuestionsLink>
      )}
    </Styled.Wrapper>
  )
}
