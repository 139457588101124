import styled, { AnyStyledComponent } from 'styled-components'

export const BackgroundColors: any = {
  red: 'rgba(183,0,64,0.90)',
  green: 'rgba(175,210,72,0.60)',
  blue: '#E5F7FF',
  plain: '#FFFFFF',
  yellow: '#F0B709',
  orange: '#F08807',
  darkorange: '#D75202',
  gray: '#F1F2F3',
}
export const TextColors: any = {
  red: '#FFFFFF',
  green: '#417505',
  blue: '#1D83AD',
  plain: '#000000',
  yellow: '#FFFFFF',
  orange: '#FFFFFF',
  darkorange: '#FFFFFF',
  gray: '#303030',
}

export const BackgroundColorsBG: any = {
  red: 'rgba(183,0,64,0.90)',
  green: '#79BB43',
  blue: '#37B1E1',
  plain: '#FFFFFF',
  yellow: '#F0B709',
  orange: '#F08807',
  darkorange: '#D75202',
  gray: '#F1F2F3',
}

export const TextColorsBG: any = {
  red: '#FFFFFF',
  green: '#FFFFFF',
  blue: '#FFFFFF',
  plain: '#000000',
  yellow: '#FFFFFF',
  orange: '#FFFFFF',
  darkorange: '#FFFFFF',
  gray: '#303030',
}

export const Wrapper: AnyStyledComponent = styled.div`
  width: 100%;
  background-color: ${({
    // eslint-disable-line
    color,
    belgium,
  }: any) =>
    belgium
      ? color
        ? BackgroundColorsBG[color]
        : BackgroundColorsBG['plain']
      : color
      ? BackgroundColors[color]
      : BackgroundColors['plain']};
  color: ${({
    // eslint-disable-line
    color,
    belgium,
  }: any) =>
    belgium
      ? color
        ? TextColorsBG[color]
        : TextColorsBG['plain']
      : color
      ? TextColors[color]
      : TextColors['plain']};
  border-radius: 3px;
  margin: 0 0 20px;
  padding: 20px;
`
export const Title: AnyStyledComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 10px;

  h3 {
  color: ${({
    // eslint-disable-line
    color,
    belgium,
  }: any) =>
    belgium
      ? color
        ? TextColorsBG[color]
        : TextColorsBG['plain']
      : color
      ? TextColors[color]
      : TextColors['plain']};
    margin: 0;
    text-align: center;
    text-decoration: underline;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 10px;
  width: 100%:
`

export const Image = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
`
