import React from 'react'
import getConfig from 'next/config'

import { Content } from '@Components'
import * as Styled from './styled'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

interface IProps {
  title: string
  text: string
  pageKind: string
}

export const Text = ({ title, text, pageKind }: IProps) => {
  // TODO: Nieuws en Blogs dynamisch maken

  // add special itemprop for recipe detail page
  const itemProp =
    pageKind === 'UMBRECIPEDETAIL'
      ? {
          itemProp: 'recipeInstructions',
        }
      : {}

  const isNews = ['UMBNEWSDETAIL', 'UMBNEWSOVERVIEW', 'UMBBLOGOVERVIEW'].indexOf(pageKind) > -1
  const isFaq =
    ['UMBFAQBROWSE', 'UMBFAQCATEGORY', 'UMBFAQOVERVIEW', 'UMBFAQQUESTION'].indexOf(pageKind) > -1

  return (
    <Content component="article" marginbottom="20" {...itemProp}>
      <Styled.Title news={isNews} faq={isFaq} site={API_SITE}>
        {title}
      </Styled.Title>
      <div dangerouslySetInnerHTML={{ __html: text }}></div>
    </Content>
  )
}
