import * as Styled from './styled'
import Link from 'next/link'

export interface IOverviewItemProps {
  title?: string
  imageUrl?: string
  imageUrl2x?: string
}

export const OverviewItem = ({ title, imageUrl, imageUrl2x }: IOverviewItemProps) => {
  // TODO: volgens mij wordt dit component helemaal nergens gebruikt

  return (
    <Styled.Wrapper>
      <Link href="/">
        <Styled.Image
          width="220"
          height="110"
          alt={title}
          src={imageUrl}
          srcSet={`${imageUrl} 1x, ${imageUrl2x} 2x`}
        />
      </Link>
      <Styled.ContentWrapper>
        {title && (
          <Styled.Title>
            <Link href="/">{title}</Link>
          </Styled.Title>
        )}
      </Styled.ContentWrapper>
    </Styled.Wrapper>
  )
}
