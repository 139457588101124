import React from 'react'
import Link from 'next/link'
import getConfig from 'next/config'
import { IMetaData } from '@Interfaces'

import * as Styled from './styled'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

interface IProps {
  tags: string[]
  meta: IMetaData
  showSocialLinks: boolean
}

export const Tags = ({ tags, meta, showSocialLinks }: IProps) => {
  let title = ''
  let url = ''
  let desc = ''

  if (meta) {
    title = meta.title
    desc = meta.description
    url = meta.url
  }

  return (
    <Styled.Wrapper>
      <Styled.TagsWrapper>
        {tags && tags.length > 0 && (
          <>
            <strong>Trefwoorden</strong>
            <Styled.Tags>
              {tags.map((item: string, i: number) => (
                <Link
                  key={i}
                  href={`/search?searchTerm=${item}`}
                  as={`/${API_SITE === 'FR' ? 'recherche' : 'zoeken'}?searchTerm=${item}`}
                >
                  <Styled.Tag>{item}</Styled.Tag>
                </Link>
              ))}
            </Styled.Tags>
          </>
        )}
        {tags && <meta itemProp="keywords" content={tags.join(',')} />}
      </Styled.TagsWrapper>
      {showSocialLinks && (
        <Styled.SocialWrapper>
          <p>Delen via</p>
          <Styled.SocialButton
            href={`http://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${desc}&source=Brood.net`}
            type="linkedin"
            title="LinkedIn"
            target="_blank"
            rel="nofollow noopener noreferrer"
          ></Styled.SocialButton>
          <Styled.SocialButton
            href={`http://twitter.com/intent/tweet?text=${title} via @vbbrood&url=${url}`}
            type="twitter"
            title="Twitter"
            target="_blank"
            rel="nofollow noopener noreferrer"
          ></Styled.SocialButton>
          <Styled.SocialButton
            href={`http://www.facebook.com/sharer.php?u=${url}`}
            type="facebook"
            title="Facebook"
            target="_blank"
            rel="nofollow noopener noreferrer"
          ></Styled.SocialButton>
          <Styled.SocialButton
            href={`mailto:?subject=${title} - Brood.net&body=Link: ${url}`}
            type="email"
            title="Email"
            target="_blank"
            rel="nofollow noopener noreferrer"
          ></Styled.SocialButton>
        </Styled.SocialWrapper>
      )}
    </Styled.Wrapper>
  )
}
