import styled, { AnyStyledComponent } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => `
    display: flex;
      height: 100px;
      justify-content: row;
    background-color: #f3f3f3;
    position: relative;
    justify-content: center;
    overflow: hidden;

    ${theme.breakpoints.up('md')} {
      height: 120px;
    }
  `}
`
export const InnerWrapper = styled.div`
  ${({ theme }) => `
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${theme.breakpoints.down('sm')} {
      display: block;
      padding-left: 10px;
      padding-top: 10px;
    }
  `}
`

export const Left = styled.div`
  ${({ theme }) => `
    text-transform: uppercase;
    font-size: 10px;
    line-height: 14px;
    text-align: left;
    height: auto;
    margin-left: 220px;

    ${theme.breakpoints.down('md')} {
      left:10px;
      margin-left: 0;
      position: absolute;
      top: 10px;
    }
  `}
`

export const Right = styled.div`
  ${({ theme }) => `
    text-transform: uppercase;
    font-size: 10px;
    line-height: 14px;
    text-align: right;
    height: auto;
    text-align: right;

    ${theme.breakpoints.down('md')} {
      left: 10px;
      top: 0;
      right: 0;
      position: absolute;
      padding-top: 50px;
      padding-right: 80px;
    }
  `}
`

export const Stamp: AnyStyledComponent = styled.div`
  ${({ theme }: any) => `
    display: inline-block;
    width: 90px;
    vertical-align: middle;
    margin-right: 20px;

    img {
      width: 90px;
    }

    ${theme.breakpoints.down('md')} {
      position: absolute;
      right: 0px;
      top: -14px;
      margin-right: 0;
  
      img {
        width: 80px;
      }
    }
  `}
`
export const EuFlag: AnyStyledComponent = styled.div`
  ${({ theme }: any) => `
    display: inline-block;
    width: 60px;
    vertical-align: middle;
    margin-right: 10px;

    img {
      width: 60px;
    }

    ${theme.breakpoints.down('md')} {
      width: 40px;

      img {
        width: 40px;
      }
    }
  `}
`

export const TextLeft: AnyStyledComponent = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 180px;
  white-space: initial;
  ${({ colored }: any) => colored && 'color: #000099;'};
  p {
    margin-bottom: 0;
  }
`
export const TextRight: AnyStyledComponent = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 220px;
  white-space: initial;
  ${({ colored }: any) => colored && 'color: #000099;'};

  p {
    margin-bottom: 0;
  }
`
