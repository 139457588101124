import React from 'react'
import getConfig from 'next/config'
import { ISocial } from '@Interfaces'

import * as Styled from './styled'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

interface IProps {
  items: ISocial[]
}

export const SocialIcons = ({ items }: IProps) => {
  return (
    <Styled.SocialIconsWrapper>
      {items &&
        items.map((item: ISocial, i: number) => {
          return (
            <a key={i} href={item.url} target="_blank" rel="nofollow noopener noreferrer">
              <Styled.SocialIcon site={API_SITE}>{item.svg}</Styled.SocialIcon>
            </a>
          )
        })}
    </Styled.SocialIconsWrapper>
  )
}
