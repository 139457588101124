import * as Styled from './styled'

export const ErrorTemplate = ({ meta }: any) => {
  return (
    <Styled.Wrapper>
      {meta.title &&
        <Styled.Title>
          {meta.title}
        </Styled.Title>
      }
      {meta.description &&
        <Styled.Description>
          {meta.description}
        </Styled.Description>
      }
    </Styled.Wrapper>
  )
}
