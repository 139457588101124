import { Button } from '@material-ui/core'
import cookie from 'react-cookies'
import { withRouter } from 'next/router'

import * as Styled from './styled'

interface ILanguagePickerProps {
  languages: ILanguage[]
  currentLanguage: string
  pickLanguage(language: string): void
}

interface ILanguage {
  label: string
  locale: string
}

const PureLanguagePicker = ({
  languages,
  currentLanguage,
  pickLanguage,
}: ILanguagePickerProps & any) => {
  const changeLanguage = (lang: string): void => {
    // Set the cookie
    cookie.save('locale', lang, { path: '/' })
    console.log(pickLanguage)
    //pickLanguage(lang)
  }

  const currentLanguageObj = languages.find((language: any) => language.locale === currentLanguage)
  console.log(currentLanguageObj)

  return (
    <Styled.ButtonBox mt="32px">
      {languages.map((language: ILanguage, index: number) => (
        <Button
          key={index}
          color="primary"
          variant="contained"
          onClick={() => changeLanguage(language.locale)}
        >
          {language.label}
        </Button>
      ))}
    </Styled.ButtonBox>
  )
}

export const LanguagePicker = withRouter(PureLanguagePicker)
