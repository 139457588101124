import getConfig from 'next/config'
import { Typography } from '@material-ui/core'
import styled, { AnyStyledComponent } from 'styled-components'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

export const Content: AnyStyledComponent = styled(Typography)`
  ${({ theme, marginbottom }: any) => `
    clear: both;
    margin-bottom: ${marginbottom || 0}px;
    overflow: auto;

    &:after {
      content: '';
      clear: both;
      display: table;
    }
    
    ul {
      list-style: disc outside;
      margin-bottom: 14px;
      padding-left: 21px;
    }

    a {
      color: ${
        ['BE', 'FR'].indexOf(API_SITE) > -1
          ? theme.palette.secondary.light
          : theme.palette.primary.light
      };
      text-decoration: none;
      &:hover: {
        text-decoration: underline;
      }
    }
    .embeditem {
      padding-top: 56.25%;
      position: relative;
      
      iframe {
        bottom: 0;
        height: 100%;
        left:0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }
    }

    h1 {
      color: ${
        ['BE', 'FR'].indexOf(API_SITE) > -1
          ? theme.palette.secondary.light
          : theme.palette.primary.light
      };
      font-size: ${theme.typography.h1.fontSize}px;
      font-family: ${theme.typography.h1.fontFamily};
      font-weight: ${theme.typography.h1.fontWeight};
      line-height: ${theme.typography.h1.lineHeight};
    }

    h2 {
      font-size: ${theme.typography.h2.fontSize}px;
      font-family: ${theme.typography.h2.fontFamily};
      font-weight: ${theme.typography.h2.fontWeight};
      line-height: ${theme.typography.h2.lineHeight};
    }

    h3 {
      font-size: ${theme.typography.h3.fontSize}px;
      font-family: ${theme.typography.h3.fontFamily};
      font-weight: ${theme.typography.h3.fontWeight};
      line-height: ${theme.typography.h3.lineHeight};
    }

    table {
      font-family: 'Melbourne', sans-serif;
    }

    img {
      padding-right: 20px;
    }

    .recipe-image {
      float: right;
      padding-left: 20px;
      padding-right: 0;
    }
  `}
`
