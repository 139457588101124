import styled, { AnyStyledComponent } from 'styled-components'

export const MenuWrapper = styled.nav`
  height: 115px;
  width: 100%;
  font-family: 'Melbourne', sans-serif;
`

export const Wrapper = styled.div`
  height: 115px;
  width: 100%;
  display: flex;
`

export const InnerMenu = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 99999;
`

export const TopMenu: AnyStyledComponent = styled.div`
  ${({ theme, site }: any) => `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${
      ['BE', 'FR'].indexOf(site) > -1 ? theme.palette.primary.light : theme.palette.secondary.main
    };
    height: 40%;

    ul {
      display: flex;
      text-decoration: none;
      list-style-type: none;
      color: ${['BE', 'FR'].indexOf(site) > -1 ? '#434240' : 'white'};
      height: 100%;
      justify-content: flex-end;
      margin: 0px;
      padding-left: 0;
    }	
  `}
`
export const TopMenuItem: AnyStyledComponent = styled.li`
  ${({ active, alternativeBackground, variant, site }: any) => `
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    line-height: 35px;
    text-transform: uppercase;
    font-size: 16px;

    a {
      color: ${['BE', 'FR'].indexOf(site) > -1 ? '#434240' : 'white'};
      display: block;
      line-height: 24px;
      text-decoration: none;
      white-space: nowrap;

      ${
        active
          ? `
            background-color: #6d5137;
            border-radius: 16px;
            margin: 7px 8px;
            padding: 3px 10px !important;
          `
          : `
            padding: 10px;
          `
      }
    }
    
    ${
      alternativeBackground && variant === 'top-menu-item'
        ? `
          a {
            background-color: rgba(${
              ['BE', 'FR'].indexOf(site) > -1 ? '0, 0, 0, .1' : '255, 255, 255, .2'
            });
            border-radius: 16px;
            padding: 5px 10px;
          }
        `
        : ``
    }
  `}
`
export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 300px;
  width: 100%;
  height: 100%;
  padding: 5px;
`

export const SearchForm = styled.form`
  flex: 1 1 auto;
  position: relative;
`

export const SearchIcon = styled.div`
  width: 18px;
  height: 18px;
  position: absolute;
  top: 15px;
  left: 10px;
  background-image: url(/images/searchIcon.svg);
  background-size: 18px 18px;
`

export const SearchInput: AnyStyledComponent = styled.input`
  ${({ visible }: any) => (visible ? 'display: block;' : 'display: none;')}
  width: 100%;
  height: 35px;
  border: none;
  border-radius: 3px;
  font-size: 15px;
  background: rgba(255, 255, 255, 0.2);
  padding: 5px 5px 5px 30px;
  caret-color: white;

  &:focus {
    outline: none;
  }
`

export const BottomMenuLink: AnyStyledComponent = styled.li`
  ${({ hover, subItems, theme, site }: any) => `
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    padding: 10px;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 23px 10px 17px 10px;
    ${hover > 0 ? 'background-color: #fff;' : ''}

    a {
      color: ${
        hover > 0
          ? ['BE', 'FR'].indexOf(site) > -1
            ? theme.palette.primary.dark
            : theme.palette.secondary.main
          : ['BE', 'FR'].indexOf(site) > -1
          ? 'white'
          : theme.palette.secondary.main
      };
      display: block;
      padding-left: 7px;
      padding-right: 7px;
      text-decoration: none;
      white-space: nowrap;
      text-rendering: optimizeLegibility;
    }

    ${
      subItems
        ? `
          padding-right: 25px;
          position: relative;

          &:after {
            border-bottom: 2px solid ${['BE', 'FR'].indexOf(site) > -1 ? 'white' : '#492605'};
            border-right: 2px solid ${['BE', 'FR'].indexOf(site) > -1 ? 'white' : '#492605'};
            content:'';
            display: block;
            height: 6px;
            position: absolute;
            right: 20px;
            transition: 0.3s;
            width: 6px;

            ${
              hover > 0
                ? `
                  top: 33px; transform: rotate(225deg);
                  border-color: ${
                    ['BE', 'FR'].indexOf(site) > -1 ? theme.palette.primary.dark : '#492605'
                  };
                `
                : 'top: 31px; transform: rotate(45deg);'
            }
          }
        `
        : ''
    }

    &:hover {
      cursor: pointer;
    }

  `}
`

export const BottomMenu: AnyStyledComponent = styled.div`
  ${({ theme }: any) => `
    background-color: ${theme.palette.primary.dark};
    height: 60%;

    ul {
      display: flex;
      text-decoration: none;
      list-style-type: none;
      color: black;
      height: 100%;
      justify-content: space-around;
      margin: 0px;
      padding: 0px;

      ${theme.breakpoints.up('lg')} {
        justify-content: flex-start;
      }
    }
  `}
`

export const FoldMenuWrapper = styled.div`
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 99999;
`

export const FoldMenuInnerWrapper: AnyStyledComponent = styled.div`
  ${({ show }: any) => (show === true ? 'display: flex;' : 'display: none;')}
  position: relative;
  flex-direction: row;
  justify-content: center;
  top: 115px;
  width: 100%;
  height: auto;
  background-color: white;
  pointer-events: all;

  ul {
    margin: 0px;
    padding: 40px 0px;

    li {
      display: inline-block;
      width: 33.3%;
      margin: 0;
      padding: 0;
      cursor: pointer;

      &:hover a {
        color: #ef6331;
      }

      a {
        text-decoration: none;
        font-size: 18px;
        color: #501310;
        letter-spacing: 0.9px;
        line-height: 34px;
      }
    }
  }
`

export const FoldMenuCenter = styled.div`
  display: block;
  max-width: 960px;
  width: 100%;
`

export const Shadow: AnyStyledComponent = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  ${({ show }: any) =>
    show === true
      ? `opacity: 1; right: 0; transition: opacity 0.5s ease-in-out;`
      : `opacity: 0; right: 100%; transition: opacity 0.5s ease-in-out, right 0.1s linear 0.75s;
  `}
  left: 0px;
  top: 0px;
  bottom: 0px;

  z-index: 99998;
`
