import React from 'react'
import getConfig from 'next/config'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { CustomLink, Youtube } from '@Components'
import { IResponsiveImageRetina } from '@Interfaces'
import * as Styled from './styled'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

export interface IHighlightBlockProps {
  title: string
  image: IResponsiveImageRetina
  text: string
  link: string
  youtubeEmbeddedCode: string
}

export const HighlightBlock = ({
  title,
  image,
  text,
  link,
  youtubeEmbeddedCode,
}: IHighlightBlockProps) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Styled.Wrapper hidePlayIcon={!!youtubeEmbeddedCode}>
      {!youtubeEmbeddedCode ? (
        link ? (
          <CustomLink url={link}>
            {matches || (image && !image.mobileUrl) ? (
              <Styled.Image
                width="300"
                height="204"
                alt={title}
                srcSet={`${image && image.desktopUrl ? image.desktopUrl + ' 1x,' : ''} ${
                  image && image.desktopRetinaUrl ? image.desktopRetinaUrl + '  2x' : ''
                }`}
              />
            ) : (
              <Styled.Image
                width="300"
                height="204"
                alt={title}
                srcSet={`${image && image.mobileUrl ? image.mobileUrl + ' 1x,' : ''} ${
                  image && image.mobileRetinaUrl ? image.mobileRetinaUrl + '  2x' : ''
                }`}
              />
            )}
          </CustomLink>
        ) : (
          <>
            {matches || (image && !image.mobileUrl) ? (
              <Styled.Image
                width="300"
                height="204"
                alt={title}
                srcSet={`${image && image.desktopUrl ? image.desktopUrl + ' 1x,' : ''} ${
                  image && image.desktopRetinaUrl ? image.desktopRetinaUrl + '  2x' : ''
                }`}
              />
            ) : (
              <Styled.Image
                width="300"
                height="204"
                alt={title}
                srcSet={`${image && image.mobileUrl ? image.mobileUrl + ' 1x,' : ''} ${
                  image && image.mobileRetinaUrl ? image.mobileRetinaUrl + '  2x' : ''
                }`}
              />
            )}
          </>
        )
      ) : (
        <Styled.YoutubeWrapper>
          <Youtube url={youtubeEmbeddedCode} />
        </Styled.YoutubeWrapper>
      )}
      <Styled.ContentWrapper site={API_SITE}>
        {title && <h4>{title}</h4>}
        {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
      </Styled.ContentWrapper>
    </Styled.Wrapper>
  )
}
