import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'

import { IStore } from '@Interfaces'
import { GeneralActions } from '@Redux/general/actions'
import { getLocale } from '@Redux/general/selectors'
import { getDictionary } from '@Redux/dictionary/selectors'
import { IntlProvider as IntlProviderBase } from '@Components'

export const IntlProvider: React.SFC<{ children: any }> = props => {
  const dispatch = useDispatch()

  const locale = useSelector((state: IStore) => getLocale(state.general))
  const dictionary = useSelector((state: IStore) => getDictionary(state.dictionary))

  const getInitialLocale = bindActionCreators(GeneralActions.GetInitialLocale, dispatch)
  const fetchData = bindActionCreators(GeneralActions.FetchData, dispatch)

  return (
    <IntlProviderBase
      locale={locale}
      dictionary={dictionary}
      fetchData={fetchData}
      getInitialLocale={getInitialLocale}
    >
      {props.children}
    </IntlProviderBase>
  )
}
