import styled, { AnyStyledComponent } from 'styled-components'

export const Wrapper: AnyStyledComponent = styled.div`
  ${({ hidePlayIcon }: any) => `
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    padding-bottom: 20px;

    &:after {
      content: '';
      display: ${hidePlayIcon ? 'none' : 'block'};
      position: absolute;
      width: 30px;
      height: 30px;
      background-image: url(/images/block-arrow.svg);
      background-size: 30px 30px;
      pointer-events:none;
      top: 10px;
      right: 20px;
  `}
`

export const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
`

export const ContentWrapper: AnyStyledComponent = styled.div`
  ${({ theme, site }: any) => `
    width: 100%;
    height: 40%;
    text-align: center;
    overflow: hidden;
    padding: 20px 0;

    h4 {
      color: ${
        ['BE', 'FR'].indexOf(site) > -1 ? theme.palette.secondary.light : theme.palette.primary.main
      };
      font-size: 20px;
      line-height: 22px;
      font-family: 'Melbourne', sans-serif;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    p {
      margin: 0px;
      font: 13px Arial;
      color: #8a8a8a;
    }
  `}
`
export const YoutubeWrapper = styled.div`
  padding-top: 6%;
  padding-bottom: 6%;
  background-color: black;
`