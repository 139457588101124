import styled, { AnyStyledComponent } from 'styled-components'

export const Title: AnyStyledComponent = styled.h1`
  ${({ theme, news, faq, site }: any) => `
    text-align: left;
    font-family: 'Melbourne', sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 10px;

    ${
      news === true
        ? `
          color: #492605;
        `
        : ['BE', 'FR'].indexOf(site) > -1
        ? `
          color: ${theme.palette.secondary.light};
        `
        : `
          color: ${theme.palette.primary.light};
        `
    }

    ${faq === true &&
      `
        color: #492605;
        line-height: 22px;
        font-weight: 400;
        font-size: 20px;
      `}
  `}
`
