import React, { FC, useEffect, useState } from 'react'
import {
  CarouselComponent,
  CarouselItem,
  CarouselContent,
  CarouselImage,
  CarouselVS,
  CarouselDual,
  CarouselProcess,
  CarouselBol,
} from './styled'
interface CarouselProps {
  items: any
}

const Carousel: FC<CarouselProps> = ({ items }: CarouselProps) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide(prevCount => {
        if (items.length - 1 === prevCount) {
          return (prevCount = 0)
        }
        return prevCount + 1
      })
    }, 7000)
    return () => clearInterval(intervalId)
  }, [items])

  if (!Array.isArray(items) || items.length <= 0) {
    return null
  }

  return (
    <CarouselComponent>
      {items.map((item, i) => (
        <CarouselItem key={i} currentSlide={currentSlide === i}>
          <h3>{item.title}</h3>
          <CarouselContent currentSlide={currentSlide === i}>
            <CarouselDual>
              <CarouselImage>
                <img src={`/images/landingpage/strongCarousel/${item?.dual1Image}.png`} />
                <div>
                  <strong>{item?.dual1Value}</strong> {item?.value}
                </div>
              </CarouselImage>
              <CarouselVS>VS</CarouselVS>
              <CarouselImage>
                <img src={`/images/landingpage/strongCarousel/${item?.dual2Image}.png`} />
                <div>
                  <strong>{item?.dual2Value}</strong> {item?.value}
                </div>
              </CarouselImage>
            </CarouselDual>

            <p>{item.paragraph}</p>
          </CarouselContent>
        </CarouselItem>
      ))}
      <CarouselProcess>
        {items.map((_, i) => (
          <CarouselBol activeBol={i <= currentSlide} onClick={() => setCurrentSlide(i)} key={i} />
        ))}
      </CarouselProcess>
    </CarouselComponent>
  )
}

export default Carousel
