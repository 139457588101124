import React, { Ref, createRef, useEffect } from 'react'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { MenuItem, Popper, Paper } from '@material-ui/core'
import * as Styled from './styled'
import Link from 'next/link'
import Router from 'next/router'
import theme from '@Themes'

interface OptionType {
  label: string
}

interface ISuggestion extends OptionType {
  url: string
}

const asRef: Ref<any> = createRef()

function renderInputComponent(inputProps: any) {
  const { inputRef = () => { }, ref, handleSubmit, state, ctaButtonText, ...other } = inputProps

  return (
    <Styled.PaperWrapper ref={asRef} >
      <Styled.Input fullWidth {...inputProps} {...other} id="searchbox-autosuggest" />
      <Styled.Button
        variant="contained"
        color="primary"
        onClick={() => handleSubmit()}
        disabled={!state.popper}
      >
        {ctaButtonText || 'Zoek'}
      </Styled.Button>
    </Styled.PaperWrapper>
  )
}

function renderSuggestion(
  suggestion: ISuggestion,
  { query, isHighlighted }: Autosuggest.RenderSuggestionParams,
) {
  const matches = match(suggestion.label, query)
  const parts = parse(suggestion.label, matches)

  const mobile = window && window.innerWidth < theme.breakpoints.values.md
  const padding = mobile ? { paddingTop: 12, paddingBottom: 12 } : {}

  // TODO: if possible to move this to useStyles?
  const menuItemStyle = {
    fontFamily: 'Arial, sans-serif',
    fontSize: 14,
    fontWeight: 400,
    ...padding,
  }

  return (
    <Link href={suggestion.url}>
      <MenuItem style={menuItemStyle} selected={isHighlighted} component="div">
        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {parts.map(part => (
            <span key={part.text} style={{ fontWeight: part.highlight ? 700 : 400 }}>
              {part.text}
            </span>
          ))}
        </div>
      </MenuItem>
    </Link>
  )
}

function getSuggestionValue(suggestion: OptionType) {
  return suggestion.label
}

interface ISearchResult {
  name: string
  url: string
}

interface IAutoCompleteBoxProps {
  searchResults?: any
  ctaButtonText?: string
  searchfieldPlaceholder?: string
  fetchSearchResults(searchTerm: string): void
  clearSearchResults(): void
}

export const AutoCompleteBox = (props: IAutoCompleteBoxProps) => {
  const {
    searchResults: { searchResults },
    ctaButtonText,
    searchfieldPlaceholder,
    fetchSearchResults,
    clearSearchResults,
  } = props

  const suggestions: ISuggestion[] = searchResults
    ? searchResults
      .map((result: ISearchResult) => ({
        label: result.name,
        url: result.url,
      }))
      .slice(0, 8)
    : []

  const [state, setState] = React.useState({
    single: '',
    popper: '',
  })

  useEffect(() => {
    const element = document.getElementById('searchbox-autosuggest')
    element ? element.focus() : null
  }, [])

  const handleSuggestionsFetchRequested = ({ value }: any) => {
    if (value.length > 1) {
      fetchSearchResults(value)
    } else {
      clearSearchResults()
    }
  }

  const handleSuggestionsClearRequested = () => {
    //clearSearchResults()
  }

  const handleChange = (name: keyof typeof state) => (
    _: React.ChangeEvent<{}>,
    { newValue }: Autosuggest.ChangeEvent,
  ) => {
    setState({
      ...state,
      [name]: newValue,
    })
  }

  const onKeyPress = ({ key }: any) => {
    if (key === 'Enter') {
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    if (!state.popper || !suggestions) return
    const htmlEl = document.querySelector('html')
    htmlEl ? htmlEl.removeAttribute('style') : null

    // onEnter the current selected item should be followed --> suggestion.url
    // if no selected the first one should be used
    let resultUrl = ''
    const exactMatch = suggestions.find(v => {
      return v.label.toLowerCase() === state.popper.toLowerCase()
    })
    if (exactMatch) {
      resultUrl = exactMatch.url
    } else {
      resultUrl = suggestions[0].url
    }
    if (resultUrl) {
      Router.push(resultUrl)
    }
  }

  const autosuggestProps = {
    renderInputComponent: (props: any) => renderInputComponent({ ...props, handleSubmit, state, ctaButtonText }),
    suggestions: suggestions || [],
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  }

  return (
    <Autosuggest
      {...autosuggestProps}
      inputProps={{
        id: 'react-autosuggest-popper',
        placeholder: searchfieldPlaceholder ? searchfieldPlaceholder : 'Stel een vraag...',
        value: state.popper,
        onChange: handleChange('popper'),
        onKeyPress,
      }}
      renderSuggestionsContainer={(options: any) => (
        <Popper anchorEl={asRef.current} open={Boolean(options.children)} style={{ zIndex: 9999 }}>
          <Paper
            square
            {...options.containerProps}
            style={{
              width: asRef.current ? asRef.current.clientWidth : '100%',
              boxShadow: 'none',
            }}
          >
            {options.children}
          </Paper>
        </Popper>
      )}
    />
  )
}
