import styled, { AnyStyledComponent } from 'styled-components'

export const Wrapper: AnyStyledComponent = styled.div`
  ${({ theme, imageMobileUrl, imageDesktopUrl }: any) => `
    ${
      imageMobileUrl
        ? `background-image: url(${imageMobileUrl});`
        : 'background-image: url(/images/mainBG.jpeg);'
    }
    ${theme.breakpoints.up('md')} {
      ${
        imageDesktopUrl
          ? `background-image: url(${imageDesktopUrl})`
          : 'background-image: url(/images/mainBG.jpeg);'
      }
    }
    background-position: center;
    background-size: cover;
    width: 100%;
  `}
`
export const InnerFAQWrapper = styled.div`
  ${({ theme }) => `
    border-top: 2px solid #e2dedc;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    ${theme.breakpoints.up('md')} {
      flex-direction: row;
      height: 200px;
    }
  `}
`

export const FAQSearch: AnyStyledComponent = styled.div`
  ${({ size }: any) => `
    position: relative;
    padding-top: 60px;
    height: ${size === 'large' ? '600px' : '440px'};
  `}
`

export const Input: AnyStyledComponent = styled.input`
  ${({ theme, showLoader }: any) => `
    order-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    background: #FBFBFB;
    box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.50);
    border: none;
    height: 44px;
    width: 100%;
    font-size: 22px;
    color: #999;
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    vertical-align: top;
    ${showLoader && `background-image: url(/images/ajax-loader.gif);`}
    background-repeat: no-repeat;
    background-position: right 10px center;

    ${theme.breakpoints.up('md')} {
      width: 400px;
    }
  `}
`

export const Button = styled.input`
  ${({ theme }) => `
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 27px;
    font-family: 'Melbourne', sans-serif;
    color: #fff;
    background: #B70040;
    border: none;
    vertical-align: top;
    height: 44px;
    text-align: center;
    width: 100%;
    cursor: pointer;

    ${theme.breakpoints.up('md')} {
      width: 110px;
    }
  `}
`

export const Title = styled.h1`
  color: #fff;
  font-size: 36px;
  line-height: 48px;
  font-weight: 400;
  font-family: 'Melbourne', sans-serif;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
  margin-bottom: 10px;

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: #fff;
    }
  }
`

export const ResultsWrapper: AnyStyledComponent = styled.ul`
  ${({ theme, show }: any) => `
    display: ${show ? 'block' : 'none'};
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    list-style-type: none;
    width: 100%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.50);
    max-width: 500px;
    font-size: 14px;
    line-height: 24px;
    z-index: 1000;
    border: 1px solid #aaaaaa;

    li {
      width: 100%;
      padding: 6px 1em 6px .4em;
      cursor: pointer;
      color: #333;
    }

    li:hover {
      background-color: ${theme.palette.primary.light}
    }
  `}
`
