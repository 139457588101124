import styled, { AnyStyledComponent } from 'styled-components'

export const Wrapper: AnyStyledComponent = styled.div`
  ${({ theme, imageMobileUrl, imageDesktopUrl, notTop }: any) => `
    background-color: gray;
    ${
    imageMobileUrl
      ? `background-image: url(${imageMobileUrl});`
      : 'background-image: url(/images/mainBG.jpeg);'
    }
    ${theme.breakpoints.up('md')} {
      ${
    imageDesktopUrl
      ? `background-image: url(${imageDesktopUrl})`
      : 'background-image: url(/images/mainBG.jpeg);'
    }
    }
    background-position: center;
    background-size: cover;
    ${notTop ? 'margin-bottom: 0px' : 'margin-bottom: 20px;'};
  `}
`
export const Inner: AnyStyledComponent = styled.div`
  ${({ size }: any) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: ${size === 'large' ? '600px' : '440px'};
  `}
`
export const FormWrapper = styled.div`
  margin: 0 auto;
`

export const Input: AnyStyledComponent = styled.input`
  ${({ theme, showLoader }: any) => `
    order-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    background: #FBFBFB;
    box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.50);
    border: none;
    height: 44px;
    width: 100%;
    font-size: 22px;
    color: #999;
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    vertical-align: top;
    ${showLoader && `background-image: url(/images/ajax-loader.gif);`}
    background-repeat: no-repeat;
    background-position: right 10px center;

    ${theme.breakpoints.up('md')} {
      width: 400px;
    }
  `}
`

export const Button = styled.button`
  ${({ theme }) => `
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 27px;
    font-family: 'Melbourne', sans-serif;
    color: #fff;
    background: ${theme.palette.secondary.light};
    border: none;
    vertical-align: top;
    height: 44px;
    text-align: center;
    width: 100%;
    cursor: pointer;

    ${theme.breakpoints.up('md')} {
      width: 110px;
    }
  `}
`

export const Title: AnyStyledComponent = styled.div`
  color: #fff;
  font-family: 'Melbourne', sans-serif;
  font-size: 60px;
  font-weight: 400;
  line-height: 60px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 40px;

  p {
    margin-bottom: 0px;
  }

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: #fff;
    }
  }
`
