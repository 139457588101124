import styled from 'styled-components'
import { MenuItem as MuiMenuItem, Select as MuiSelect } from '@material-ui/core'

export const MenuItem = styled(MuiMenuItem)``

export const Select = styled(MuiSelect)`
  background: #dbd9d4;
  color: #727272;
  font-family: 'Melbourne', sans-serif;
  font-size: 18px;
  line-height: 20px;
  text-indent: 10px;
`
