import styled, { AnyStyledComponent } from 'styled-components'

export const Wrapper = styled.nav`
  text-align: center;
`

export const List = styled.ul`
  list-style: none outside;
  margin: 0;
  padding-left: 0;

  li {
    display: inline;
    list-style-type: none;
    font-family: 'Melbourne', sans-serif;
    font-size: 18px;
    line-height: 18px;

    &:focus {
      outline: none;
    }
  }
`

export const Page: AnyStyledComponent = styled.button`
  ${({ active, underline, theme, site }: any) => `
    border: 0;
    border-radius: 3px;
    color: ${
      ['BE', 'FR'].indexOf(site) > -1 ? theme.palette.primary.dark : theme.palette.primary.main
    };
    display: inline-block;
    font-family: 'Melbourne', sans-serif;
    font-size: 18px;
    font-weight: normal;
    padding: 3px 5px 2px;
    margin-left: 1px;
    margin-right: 1px;
    min-width: 20px;
    text-align: center;
    text-decoration: ${underline ? 'underline' : 'none'};
    ${!active && 'cursor: pointer;'}
    background-color: transparent;

    &:focus {
        outline: none;
    }

    ${
      active
        ? `
          background-color: ${
            ['BE', 'FR'].indexOf(site) > -1
              ? theme.palette.primary.dark
              : theme.palette.primary.main
          };
          color: #fff;
        `
        : ''
    }
  `}
`
