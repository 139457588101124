import styled, { AnyStyledComponent } from 'styled-components'

export const Wrapper = styled.section`
  ${({ theme }) => `
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    overflow-wrap: break-word;
    margin-bottom: 10px;

    ${theme.breakpoints.up('md')} {
      flex-direction: row;
    }
  `}
`
export const TagsWrapper = styled.div`
  ${({ theme }) => `
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    > strong {
      display: block;
      font-size: 16px;
      margin: 0 0 10px;
    }

    ${theme.breakpoints.up('md')} {
      flex-direction: row;

      > strong {
        font-weight: normal;
        display: block;
        margin: 5px 20px 0 0;
      }
    }
  `}
`

export const Tags = styled.div`
  margin: 0px;
  padding: 0px;
  line-height: 24px;
`

export const Tag = styled.a`
  ${({ theme }) => `
    background: #f0f0f0;
    border-radius: 40px;
    color: #727272;
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
    margin: 5px;
    overflow-wrap: normal;
    padding: 10px 15px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    ${theme.breakpoints.up('md')} {
      padding: 4px;
      padding-left: 10px;
      padding-right: 10px;
    }
  `}
`
export const SocialWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: baseline;

  > p {
    flex: 1 0 auto;
    font-size: 16px;
    margin-bottom: 0;
  }
`

export const SocialButton: AnyStyledComponent = styled.a`
  ${({ type }: any) => `
    width: 20px;
    height: 20px;
    background: no-repeat;
    ${type === 'linkedin' ? 'background-image: url(/images/icons/icon-share-linkedin.png);' : ''}
    ${type === 'twitter' ? 'background-image: url(/images/icons/icon-share-twitter.png);' : ''}
    ${type === 'facebook' ? 'background-image: url(/images/icons/icon-share-facebook.png);' : ''}
    ${type === 'email' ? 'background-image: url(/images/icons/icon-share-email.png);' : ''}
    display: flex;
    margin: 0 0 0 8px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
  `}
`
