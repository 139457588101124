import React, { useEffect } from 'react'
import Router from 'next/router'
import { useSelector, useDispatch } from 'react-redux'
import { Grid } from '@material-ui/core'
import qs from 'query-string'

import { Select } from '@Components'
import { IStore } from '@Interfaces'
import { RecipeActions } from '@Actions'
import { TranslationKeys } from '@Definitions';
import { getTranslator } from '@Redux/dictionary/selectors'
import { getRecipeFilters, getRecipeSearchTerm } from '@Redux/recipes/selectors'

import * as Styled from './styled'

interface IRecipeFilterProps {
  filters: IRecipeFilters[]
  query?: string
}

interface IRecipeFilters {
  filterValues: IRecipeFilterItem[]
}

interface IRecipeFilterItem {
  filterValue: number
  filterName: string
}

export const RecipeFilter = ({ filters, query }: IRecipeFilterProps) => {
  const dispatch = useDispatch()
  const t = useSelector((state: IStore) => getTranslator(state.dictionary))
  
  const filtersFromStore = useSelector((state: IStore) => getRecipeFilters(state.recipes))
  const searchTermFromStore = useSelector((state: IStore) => getRecipeSearchTerm(state.recipes))

  const parsedQs = qs.parse(`?${query}`)
  const page = parseInt(parsedQs.page as string, 10) || 1

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, filterIndex: number) => {
    const currentValue = e.target.value.toString()
    // set to store
    let result: any = [...filtersFromStore]

    // if currentFilters is empty --> fill with length of amount of filters
    if (result.length === 0) {
      result = filters.map(() => {
        return null
      })
    }

    // set value in correct filter position
    if (result.length > filterIndex) {
      result[filterIndex] = currentValue
    }

    dispatch(RecipeActions.SetFilters(result))

    // remove empty values
    const filtersString = result.filter((i: number) => i > 0).join(',')
    dispatch(RecipeActions.FetchData(searchTermFromStore, filtersString, page))

    //TODO: vervangen van slug door iets uit de API
    let slug = Router.asPath.substr(0, Router.asPath.indexOf('?'))
    Router.push('/[slug]', `${slug}?searchTerm=${searchTermFromStore}&filters=${filtersString}`, { shallow: true });
  }

  const onResetClick = () => {
    let slug = Router.asPath.substr(0, Router.asPath.indexOf('?'))
    Router.push('/[slug]', `${slug}?searchTerm=${searchTermFromStore}`, { shallow: true })
    
    // clear store
    dispatch(RecipeActions.Reset())
    dispatch(RecipeActions.FetchData(searchTermFromStore, '', 1))
  }

  useEffect(() => {
    // first create default filter object based on amount of dropdowns
    let currentFilters: any = []
    if (filters) {
      currentFilters = filters.map(() => {
        return null
      })
    }

    // get values based on query
    if (query) {
      let params = new URLSearchParams(query)
      const paramFilters = params ? params.get("filters") : ''
      const filtersFromQuery = paramFilters ? paramFilters.toString() : ''
      if (filtersFromQuery) {

        // loop through filters and add value to correct index in currentFilters
        filters.map((item, filterIndex) => {
          item.filterValues.map((f: IRecipeFilterItem) => {
            if (f.filterValue > 0 && filtersFromQuery.indexOf(f.filterValue.toString()) > -1) {
              currentFilters[filterIndex] = f.filterValue
            }
          })
        })

        dispatch(RecipeActions.SetFilters(currentFilters))
      }
    }

  }, [])

  return (
    <Styled.Wrapper>
      <Grid container spacing={2} alignItems="center">
        {filters &&
          filters.map((item, filterIndex: number) => {
            return (
              <Grid item xs={12} md={3} key={filterIndex}>
                <Select
                  label={item.filterValues[0].filterName}
                  value={item.filterValues.map((f: IRecipeFilterItem) => {
                    return { name: f.filterName, id: f.filterValue }
                  })}
                  currentValue={filtersFromStore.length > 0 ? filtersFromStore[filterIndex] : '0'}
                  name="filters"
                  onChange={(e: any) => handleChange(e, filterIndex)}
                />
              </Grid>
            )
          })}
        <Grid item xs={12} md={3}>
          <Styled.ResetLink onClick={() => onResetClick()}>{t(TranslationKeys.ResetFilters)}</Styled.ResetLink>
        </Grid>
      </Grid>
    </Styled.Wrapper>
  )
}
