import React from 'react'

import * as Styled from './styled'

export interface IBreadcrumbsProp {
  breadcrumbs: IBreadcrumb[]
  isCampaign?: boolean
}

export interface IBreadcrumb {
  name: string
  url: string
}

export const Breadcrumbs = ({ breadcrumbs, isCampaign }: IBreadcrumbsProp) => {
  return (
    <Styled.Wrapper
		style={{ display: isCampaign ? 'none' : 'block' }}
	>
      {breadcrumbs.map((breadcrumb: IBreadcrumb, index: number) => {
        if (index !== breadcrumbs.length - 1) {
          return (
            <span key={index}>
              {breadcrumb.url ? <a href={breadcrumb.url}>{breadcrumb.name}</a> : breadcrumb.name}
              <Styled.Arrow />
            </span>
          )
        } else {
          return <span key={index}>{breadcrumb.name}</span>
        }
      })}
    </Styled.Wrapper>
  )
}
