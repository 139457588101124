import styled from 'styled-components'

export const Wrapper = styled.section`
  width: 100%;
  padding: 20px;
  border-radius: 3px;
  background-color: #e5f7ff;
  color: #1d83ad;
`

export const Title = styled.h2`
  color: #1D83AD;
  font-family: 'Melbourne', sans-serif;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 22px;
  text-align: center;
`
export const RelatedLink = styled.a`
  color: #1d83ad;
  display: block;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  font-family: 'Melbourne', sans-serif;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
export const ItemWrapper = styled.div`
  margin-bottom: 10px;
`

export const Time = styled.time`
  opacity: 0.5;
`