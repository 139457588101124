import { INITIAL_STATE } from './reducer'
import { IGeneralStore } from '@Interfaces'

export const getHeaderData = (state: IGeneralStore.IInitialState): any => {
  return state.header
}

export const getFooterData = (state: IGeneralStore.IInitialState): any => {
  return state.footer
}

export const getSiteTitle = (state: IGeneralStore.IInitialState): any => {
  return state.siteTitle
}

export const getPopupContent = (state: IGeneralStore.IInitialState): any => {
  const { popupContent, popupLabelNo, popupLabelYes, popupLabelNoLink } = state

  return {
    content: popupContent,
    yesLabel: popupLabelYes,
    noLabel: popupLabelNo,
    noLink: popupLabelNoLink,
  }
}

export const getSearchCategories = (state: IGeneralStore.IInitialState): any => {
  return state.searchCategories
}

export const getActiveSearchCategory = (state: IGeneralStore.IInitialState): any => {
  return state.activeSearchCategories
}

export const getGeneralSearchTerm = (state: IGeneralStore.IInitialState): any => {
  return state.searchTerm
}

export const getLocale = (state = INITIAL_STATE) => state && state.currentLocale

export default {
  getFooterData,
  getHeaderData,
  getSiteTitle,
  getPopupContent,
  getSearchCategories,
}
