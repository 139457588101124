import styled, { AnyStyledComponent } from 'styled-components'

export const Title: AnyStyledComponent = styled.h1`
  ${({ theme, site }: any) => `
    color: ${
      ['BE', 'FR'].indexOf(site) > -1 ? theme.palette.secondary.light : theme.palette.primary.light
    };
    font-family: 'Melbourne', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 10px;
  `}
`
