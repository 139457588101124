import Link from 'next/link'
import React, { FC } from 'react'
import {
  PageListComponent,
  PageListContent,
  PageListImage,
  PageListItem,
  PageListTitle,
  PageListVideo,
} from './styled'

import { Youtube } from '../../Youtube/index'

interface PageListProps {
  linkenArry: {
    video?: string
    image: string
    imageHeight: string
    cropImageHeight?: string
    imagePosistion: string
    title: string
    contentId: string
    fontFamily: string
    fontSize: string
    backgroundColor: string
    secondaryColor: string
    subTitle?: string
    link: string
    boxWidth: string
    boxHeight: string
  }[]
}

const PageList: FC<PageListProps> = ({ linkenArry }: PageListProps) => {
  return (
    <PageListComponent>
      {linkenArry.map((link, i) => (
        <PageListItem
          key={i}
          backgroundColor={link.backgroundColor}
          secondaryColor={link.secondaryColor}
          contentOrder={link.contentId}
          boxWidth={link.boxWidth}
          boxHeight={link.boxHeight}
        >
          {link.image || link.video ? (
              link.image ? (
                <Link href={link.link} passHref>
                  <div>
                    <PageListImage
                      imageHeight={link.imageHeight}
                      imagePosistion={link.imagePosistion}
                      contentOrder={link.contentId}
                      cropImageHeight={link.cropImageHeight}
                    >
                      <img src={`/images/landingpage/links/Brood${link.image}.png`} alt={link.image} />
                    </PageListImage>
                    <PageListContent contentOrder={link.contentId} center={false}>
                      <PageListTitle fontFamily={link.fontFamily} fontSize={link.fontSize}>
                        {link.title}
                      </PageListTitle>
                      {
                        link.subTitle ? (<h5>{link.subTitle}</h5>) : (<></>)
                      }
                    </PageListContent>
                  </div>
                </Link>
              ) : (
              <PageListVideo contentOrder={link.contentId} className="noCornerAnim">
                {link.video ? <Youtube code={link.video} /> : null}
              </PageListVideo>)
            
          ) : (
            <Link href={link.link} passHref>
              <div style={{height: "100%"}}>
                <PageListContent contentOrder={link.contentId} center={true}>
                  <PageListTitle fontFamily={link.fontFamily} fontSize={link.fontSize}>
                    {link.title}
                  </PageListTitle>
                  {
                    link.subTitle ? (<h5>{link.subTitle}</h5>) : (<></>)
                  }
              </PageListContent>
              </div>
            </Link>
          )}
        </PageListItem>
      ))}
    </PageListComponent>
  )
}

export default PageList
