import React from 'react'
import { Grid } from '@material-ui/core'
import { Container } from '@Components'
import { AutoCompleteBox } from '@Containers'
import { IResponsiveImage } from '@Interfaces'
import * as Styled from './styled'

interface IFAQSearch {
  image?: IResponsiveImage
  bigHero: boolean
  title: string
  ctaButtonText?: string
  searchfieldPlaceholder?: string
}

export const FAQSearch = ({
  image,
  bigHero,
  title,
  ctaButtonText,
  searchfieldPlaceholder,
}: IFAQSearch) => {
  const size = bigHero ? 'large' : 'medium'

  return (
    <Styled.Wrapper
      imageDesktopUrl={image && image.desktopUrl}
      imageMobileUrl={image && image.mobileUrl}
    >
      <Container>
        <Styled.FAQSearch size={size}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={7} lg={7}>
              <Styled.Title dangerouslySetInnerHTML={{ __html: title }} />
            </Grid>
            <Grid item xs={12} md={9} lg={7}>
              <AutoCompleteBox
                ctaButtonText={ctaButtonText}
                searchfieldPlaceholder={searchfieldPlaceholder}
              />
            </Grid>
          </Grid>
        </Styled.FAQSearch>
      </Container>
    </Styled.Wrapper>
  )
}
