import React, { FC } from 'react'
import { HeroBannerComponent, HeroBannerImage } from './styled'

interface HeroBannerProps {
  title: string
  subTitle: string
  paragraph: string
  button: string
}

const HeroBanner: FC<HeroBannerProps> = ({ title, subTitle, paragraph }: HeroBannerProps) => {
  return (
    <HeroBannerComponent>
      <HeroBannerImage>
        <img src="/images/landingpage/HeroBannerBrood.webp" />
      </HeroBannerImage>
      <h1>{title}</h1>
      <h2>{subTitle}</h2>
      <p>{paragraph}</p>
    </HeroBannerComponent>
  )
}

export default HeroBanner
