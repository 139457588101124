import theme from '@Themes'
import styled from 'styled-components'

const LinkBlockComponent = styled.section`
  a {
    text-decoration: none;
    background-color: #f5ece0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 40px 0;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    max-width: 1030px;
    h3 {
      color: #4d0e27;
      text-align: center;
      font-size: 49px;
      line-height: 49px;
      max-width: 250px;
      font-family: 'Playfair Display', serif;
    }
    ${theme.breakpoints.up('lg')} {
      flex-direction: row;
      gap: 40px;
      h3 {
        font-size: 54px;
      }
    }
  }
`
const LinkBlockImageM = styled.div`
  max-width: 100vw;
  overflow: hidden;
  img {
    width: 375px;
  }
  ${theme.breakpoints.up('lg')} {
    display: none;
  }
`
const LinkBlockImageD = styled.div`
  display: none;
  ${theme.breakpoints.up('lg')} {
    display: block;
  }
`

export { LinkBlockComponent, LinkBlockImageM, LinkBlockImageD }
