import React from 'react'
import getConfig from 'next/config'
import { useSelector } from 'react-redux'
import { usePagination } from '@material-ui/lab/Pagination'
import { getTranslator } from '@Redux/dictionary/selectors'
import { TranslationKeys } from '@Definitions'
import { IStore } from '@Interfaces'

import * as Styled from './styled'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

interface IPaginationProps {
  currentPage: number
  totalPage: number
  onChange?: (pageNumber: number) => void
}

export const Pagination = ({ currentPage, totalPage, onChange }: IPaginationProps) => {
  const t = useSelector((state: IStore) => getTranslator(state.dictionary))

  const { items } = usePagination({
    boundaryCount: 4, // number of pages next to prev / next
    siblingCount: 4, // number of pages on eiter side of currentPage
    count: totalPage,
    page: currentPage,
  })

  const onClickHandler = (page: number) => {
    if (onChange) {
      onChange(page)
    }
  }

  const renderPrevNextButtons = (item: any, page: number, type: any) => { // eslint-disable-line
    if (type == 'previous') {
      if (page > 0) {
        return (
          <Styled.Page
            underline
            type="button"
            {...item}
            onClick={() => onClickHandler(page)}
            site={API_SITE}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: `&laquo; ${t(TranslationKeys.PreviousPage)}`,
              }}
            ></span>
          </Styled.Page>
        )
      } else {
        return null
      }
    } else if (type === 'next') {
      if (page <= totalPage) {
        return (
          <Styled.Page
            underline
            type="button"
            {...item}
            onClick={() => onClickHandler(page)}
            site={API_SITE}
          >
            <span
              dangerouslySetInnerHTML={{ __html: `${t(TranslationKeys.NextPage)} &raquo;` }}
            ></span>
          </Styled.Page>
        )
      } else {
        return null
      }
    }

    return type
  }

  // check on length > 3, because default prev, 1, next ;-)
  return (
    <Styled.Wrapper>
      <Styled.List>
        {items &&
          items.length > 3 &&
          items.map(({ page, type, selected, ...item }, index) => {
            let children

            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
              children = <span dangerouslySetInnerHTML={{ __html: '&hellip;' }}></span>
            } else if (type === 'page') {
              children = (
                <Styled.Page
                  active={selected}
                  type="button"
                  {...item}
                  onClick={() => !selected && onClickHandler(page)}
                  site={API_SITE}
                >
                  {page}
                </Styled.Page>
              )
            } else {
              children = renderPrevNextButtons(item, page, type)
            }

            return <li key={index}>{children}</li>
          })}
      </Styled.List>
    </Styled.Wrapper>
  )
}
