import { INITIAL_STATE } from './reducer'
import getConfig from 'next/config'
import { IDictionaryStore } from '@Interfaces'

const {
  publicRuntimeConfig: { SHOW_TRANSLATION_KEYS },
} = getConfig()

export const getDictionary = (state = INITIAL_STATE) => state && state.dictionary
export const getTranslator = (state = INITIAL_STATE): ((key: string) => string) => {
  const dictionary = getDictionary(state)

  return (key: string): string => {
    return dictionary[key as keyof IDictionaryStore.IDictionary] &&
      dictionary[key as keyof IDictionaryStore.IDictionary] !== key
      ? dictionary[key as keyof IDictionaryStore.IDictionary]
      : SHOW_TRANSLATION_KEYS
      ? key
      : ''
  }
}

export default {
  getDictionary,
  getTranslator,
}
