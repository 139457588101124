import * as Styled from './styled'

interface IBlockListProps {
  items?: IBlocks[]
}

interface IBlocks {
  imageUrl?: string
  linkUrl?: string
  title?: string
  intro?: string
}

export const BlockList = ({ items }: IBlockListProps) => {
  return (
    <Styled.Wrapper>
      {items &&
        items.map((block: any, i: number) => {
          return (
            <Styled.BlockWrapper key={i}>
              <Styled.InnerWrapper>
                <Styled.ImageWrapper imageUrl={block.imageUrl} />
                <Styled.ContentWrapper>
                  <h3>
                    <a href={block.linkUrl || ''}>{block.title}</a>
                  </h3>
                  <p>{block.intro}</p>
                </Styled.ContentWrapper>
              </Styled.InnerWrapper>
            </Styled.BlockWrapper>
          )
        })}
    </Styled.Wrapper>
  )
}
