import React from 'react'
import styled, { css } from 'styled-components'
import getConfig from 'next/config'

import { GTMDownloadEvent } from '@Lib/helpers'
import { IResponsiveImage } from '@Interfaces'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

const A = styled.a`
  ${({ theme }: any) => css`
    color: ${['BE', 'FR'].indexOf(API_SITE) > -1
      ? theme.palette.secondary.light
      : theme.palette.primary.light};
    text-decoration: none;

    &:hover: {
      text-decoration: underline;
    }

    img {
      max-width: 200px;
      display: block;
    }
  `}
`

interface IProps {
  link: string
  buttonText: string
  image: IResponsiveImage
}

export const DownloadButton: React.FC<IProps> = ({ link, buttonText, image }: IProps) => {
  return (
    <A href={link} onClick={() => GTMDownloadEvent(link)}>
      <img src={image.desktopUrl} />
      {buttonText}
    </A>
  )
}
