import React from 'react'
import getConfig from 'next/config'
import moment from 'src/local_Packages/moment/moment'
import { Content } from '@Components'
import * as Styled from './styled'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

interface IBlogContentProps {
  title: string
  text: string
  publicationDate?: string
}

export const BlogContent = ({ title, text, publicationDate }: IBlogContentProps) => {
  return (
    <Content component="article" marginbottom="20">
      <Styled.Title site={API_SITE}>{title}</Styled.Title>
      {publicationDate && <time>{moment(publicationDate).format('D MMMM YYYY')}</time>}
      <div dangerouslySetInnerHTML={{ __html: text }}></div>
    </Content>
  )
}
