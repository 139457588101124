import React from 'react'
import Link from 'next/link'
import { Collapse, Box } from '@material-ui/core'

import { INestableMenuItem } from '@Components'
import { getHrefOfSlug } from '@Lib'

import * as Styled from './styled'

interface IItemProps {
  item: INestableMenuItem
  currentSlug?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nested?: any
  toggleMenu: () => void
}

export const ResponsiveDrawerItem: React.FC<IItemProps> = ({
  item,
  currentSlug,
  nested,
  toggleMenu,
}: IItemProps) => {
  const active = item.url === currentSlug
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  if (item.subItems && item.subItems.length && item.subItems.length > 0) {
    return (
      <React.Fragment>
        <Styled.ListItem nested={nested}>
          {item.url.indexOf('http') > -1 ? (
            <a href={item.url}>
              <Styled.ListItemText
                onClick={toggleMenu}
                style={{ color: active ? 'primary' : 'primary' }}
                nested={nested}
                variant="body2"
              >
                {item.name}
              </Styled.ListItemText>
            </a>
          ) : (
            <Link href={getHrefOfSlug(item.url)} as={item.url}>
              <Styled.ListItemText
                onClick={toggleMenu}
                style={{ color: active ? 'primary' : 'primary' }}
                nested={nested}
                variant="body2"
              >
                {item.name}
              </Styled.ListItemText>
            </Link>
          )}
          <Box onClick={handleClick}>
            <Styled.Arrow open={open} />
          </Box>
        </Styled.ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Styled.List>
            {item.subItems.map((subItem: INestableMenuItem, index: number) => (
              <ResponsiveDrawerItem
                key={index}
                item={subItem}
                nested={nested ? (nested as number) + 1 : 1}
                toggleMenu={toggleMenu}
                currentSlug={currentSlug}
              />
            ))}
          </Styled.List>
        </Collapse>
      </React.Fragment>
    )
  }

  return (
    <Styled.ListItem nested={nested} className={item.url.indexOf('http') > -1 ? 'isExternalLink' : 'none'}>
      {item.url.indexOf('http') > -1 ? (
        <a href={item.url} className='externalLink'>
          <Styled.ListItemText nested={nested} onClick={toggleMenu}>
            {item.name}
          </Styled.ListItemText>
        </a>
      ) : (
        <Link href={getHrefOfSlug(item.url)} as={item.url}>
          <Styled.ListItemText nested={nested} onClick={toggleMenu}>
            {item.name}
          </Styled.ListItemText>
        </Link>
      )}
    </Styled.ListItem>
  )
}
