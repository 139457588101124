import styled, { AnyStyledComponent, css } from 'styled-components'

export const Wrapper: AnyStyledComponent = styled.section`
  ${({ theme, isNotNL }: any) => css`
    background: ${isNotNL ? theme.palette.secondary.light : theme.palette.primary.main};
    border-radius: 3px;
    color: #fff;
    padding: 20px;
  `}
`

export const List = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: outside none;
  margin: 0 -20px;
  padding-left: 0;
`

export const ListItem = styled.li`
  ${({ theme }: any) => `
    flex: 0 0 100%;
    padding: 0 20px;

    ${theme.breakpoints.up('sm')}{
      flex: 0 0 50%;
    }

    ${theme.breakpoints.up('md')}{
      flex: 0 0 33.33%;
    }

    a {
      color: #fff;
      display: block;
      font-size: 18px;
      font-family: 'Melbourne', sans-serif;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  `}
`
