import styled, { AnyStyledComponent } from 'styled-components'

export const Wrapper: AnyStyledComponent = styled.div`
  ${({ color, textColor, site, noBorder }: any) => `
    height: 100%;
    color: ${textColor ? textColor : '#fff'};
    padding: 20px;
    overflow: hidden;
    background-color: ${color ? color : '#E97D44'};
    
    ${
      ['BE', 'FR'].indexOf(site) > -1 && !noBorder
        ? `
          border-right: 1px solid #888888;
        `
        : ''
    }
  `}
`

export const ContentWrapper: AnyStyledComponent = styled.div`
  ${({ site, theme }: any) => `
    h4 {
      font-size: 28px;
      line-height: 28px;
      margin-bottom: 10px;
      font-weight: 400;
      font-family: 'Melbourne', sans-serif;
      color: ${theme.palette.primary.dark};

      a {
        color: ${['BE', 'FR'].indexOf(site) > -1 ? theme.palette.primary.dark : '#FFF0F5'};
      }
    }

    div {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 14px;
    }
  `}
`
