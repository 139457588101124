import React, { FC } from 'react'
import { LinkBlockComponent, LinkBlockImageM, LinkBlockImageD } from './styled'

interface LinkBlockProps {
  title: string
  img: string
}

const LinkBlock: FC<LinkBlockProps> = ({ title, img }: LinkBlockProps) => {
  return (
    <LinkBlockComponent>
      <a href="?page=home">
        <h3>{title}</h3>
        <LinkBlockImageM>
          <img src={`/images/landingpage/detailpage/${img}.webp`} />
        </LinkBlockImageM>

        <LinkBlockImageD>
          <img src={`/images/landingpage/detailpage/desktop/${img}.webp`} />
        </LinkBlockImageD>
      </a>
    </LinkBlockComponent>
  )
}

export default LinkBlock
