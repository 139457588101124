import styled, { AnyStyledComponent } from 'styled-components'
import { IconButton } from '@material-ui/core'

export const Wrapper: AnyStyledComponent = styled.div`
${({ theme, imageMobileUrl, imageDesktopUrl, size, hasVideo, notTop }: any) => `
    align-items: center;
    background-color: gray;
    ${imageMobileUrl ? `background-image: url(${imageMobileUrl});` : 'background-image: url(/images/mainBG.webp);'}
    ${theme.breakpoints.up('md')} {
      ${imageDesktopUrl ? `background-image: url(${imageDesktopUrl})` : 'background-image: url(/images/mainBG.webp);'}
    }
    background-position: center;
    background-size: cover;
    display: flex;
    height: ${size === 'large' ? '600px' : '440px'};
    ${notTop ? 'margin-bottom: 0px' : 'margin-bottom: 20px;'};
    width: 100%;
    ${hasVideo && 'cursor: pointer;'}
  `}
`

export const TextWrapper: AnyStyledComponent = styled.div`
  ${({ theme, center }: any) => `
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    ${theme.breakpoints.up('md')} {
      ${
    center
      ? 'left: auto; justify-content: center; width: 100%; position: inherit;'
      : 'left: 10%; justify-content: flex-start; width: auto;'
    }
    }

    a {
      color: white;
    }

    h2 {
      a {
        width: 30px;
        height: 30px;
        margin: 0px;
      }
    }
  `}
`

export const Title: AnyStyledComponent = styled.h1`
  color: white;
  margin: 0px;

  ${({ center }: any) =>
    center
      ? 'display: flex; justify-content: center; width: 100%; text-align: center; flex-wrap: wrap;'
      : ''}

  p {
    margin: 10px 0;
    padding: 0;
    font-size: 67px;
    line-height: 67px;
    text-decoration: none;
    text-shadow: 0 2px 39px rgba(0, 0, 0, 0.37);
    font-family: 'Melbourne', sans-serif;
    font-weight: bold;
  }

  h1 {
    margin: 10px 0;
    padding: 0;
    font-size: 67px;
    line-height: 67px;
    text-decoration: none;
    text-shadow: 0 2px 39px rgba(0, 0, 0, 0.37);
    font-family: 'Melbourne', sans-serif;
    font-weight: bold;
  }
`

export const TitleLink: AnyStyledComponent = styled.a`
  color: white;
  margin: 0px;
  ${({ center }: any) =>
    center ? 'display: flex; justify-content: center; width: 100%; flex-wrap: wrap;' : ''}
  text-decoration: none;

  p {
    margin: 10px 0;
    padding: 0;
    font-size: 67px;
    line-height: 67px;
    margin: 0px;
    text-decoration: none;
    text-shadow: 0 2px 39px rgba(0, 0, 0, 0.37);
    font-family: 'Melbourne', sans-serif;
    font-weight: bold;
  }
`

export const SubTitle: AnyStyledComponent = styled.h2`
  display: inline-block;
  color: white;
  ${({ center }: any) => (center ? 'display: flex; justify-content: center; width: 100%;' : '')}
  padding: 0;
  font-weight: 400;
  font-size: 34px;
  margin: 0px;
  text-decoration: none;
  font-family: 'Melbourne', sans-serif;
  vertical-align: middle;
`

export const CenterButton = styled.div`
  text-align: center;
  background-color: #ef6331;
  padding: 7px 15px 8px 15px;
  margin-top: 20px;
  font-family: 'Melbourne', sans-serif;
  font-size: 20px;
  line-height: 24px;
  border-radius: 18.5px;
  color: white;
  cursor: pointer;

  &:after {
    content: ' ›';
  }

  &:hover {
    background-color: #d65a2d;
  }
`

export const CenterButtonWrapper = styled.a`
  text-decoration: none;
`

export const InnerTextWrapper: AnyStyledComponent = styled.div`
  ${({ center }: any) =>
    center &&
    'display: flex; flex-direction: column; align-items: center; justify-content: center;'};
`

export const LinkButton = styled.div`
  width: 45px;
  height: 38px;
  background-image: url(/images/icons/arrow.svg);
  background-position: center;
  background-size: cover;
  position: relative;
  top: 25px;
  margin-right: 10px;
  > a {
    bottom: 0;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`

export const VideoButton = styled.div`
  width: 43px;
  height: 43px;
  background-image: url(/images/icons/playArrow.svg);
  background-position: center;
  background-size: cover;
  display: inline-block;
  margin-left: 15px;
  vertical-align: middle;
`

export const Modal = styled.div`
  ${({ theme }: any) => `
    background-color: #fff;
    box-shadow: 0 8px 17px 0 rgba(0,0,0,0.1);
    max-width: 500px;
    left: 50%;
    outline: 0;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 20px);
    z-index: 100000;
    ${theme.breakpoints.up('md')} {
      max-width: 800px;
    }
  `}
`

export const ModalCloseButton = styled(IconButton)`
  color: rgba(255, 255, 255, 0.65);
  top: -50px;
  position: absolute;
  right: -15px;
`
