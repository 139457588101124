import styled, { css, AnyStyledComponent } from 'styled-components'

export const Wrapper: AnyStyledComponent = styled.div`
  ${({ isNotNL, theme }: any) => css`
    font-family: 'Melbourne', sans-serif;
    background-color: ${isNotNL ? theme.palette.secondary.light : '#e5f7ff'};
    background-position: 90% 5%;
    background-repeat: no-repeat;
    background-size: 17px 33px;
    border-radius: 3px;
    color: #0094b7;
    margin-bottom: 20px;
    min-height: 62px;
    padding: 5px 10px 45px;
    position: relative;
  `}
`

export const Title: AnyStyledComponent = styled.h2`
  ${({ isNotNL }: any) => css`
    color: ${isNotNL ? 'white' : '#1d83ad'};
    font-size: 28px;
    line-height: 38px;
    font-weight: 300;
    margin: 0px;
    padding: 10px;
    font-family: 'Melbourne', sans-serif;
  `}
`

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
`

export const Item: AnyStyledComponent = styled.li`
  ${({ isNotNL }: any) => css`
    a {
      color: ${isNotNL ? 'white' : '#1d83ad'};
      display: block;
      font-size: 18px;
      line-height: 1;
      -webkit-transition: all 0.8s ease-in-out;
      -moz-transition: all 0.8s ease-in-out;
      -o-transition: all 0.8s ease-in-out;
      transition: all 0.8s ease-in-out;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-top: 5px;
      text-decoration: none;
    }

    a:hover {
      color: #0a455d;
      display: block;
      text-decoration: underline;
      font-size: 18px;
      line-height: 1;
    }
  `}
`

export const AllQuestionsLink: AnyStyledComponent = styled.a`
  ${({ isNotNL }: any) => css`
    text-align: right;
    float: none;
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 14px;
    line-height: 20px;
    margin-top: 15px;
    color: ${isNotNL ? 'white' : '#81817c'};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  `}
`
