import * as Styled from './styled'
// @ts-ignore
import { TwitterTimelineEmbed } from 'react-twitter-embed'

export const TwitterWidget = () => {

  return (
    <Styled.Wrapper>
      <TwitterTimelineEmbed
        sourceType="profile"
        screenName="brood_net"
        options={{ height: 600 }}
        lang="nl"
      />
    </Styled.Wrapper>
  )
}