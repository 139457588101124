import React, { FC } from 'react'
import { VideoBlockComponent } from './styled'

const VideoBlock: FC = () => {
  return (
    <VideoBlockComponent>
      <iframe
        width="1030"
        height="600"
        src="https://www.youtube-nocookie.com/embed/WPJLydy6Fio"
        title="YouTube video player"
      ></iframe>
    </VideoBlockComponent>
  )
}

export default VideoBlock
