import React from 'react'
import getConfig from 'next/config'
import { Hidden } from '@material-ui/core'

import { ISocial, IResponsiveImage } from '@Interfaces'
import { Logo, SearchBox, SocialIcons } from '@Components'

import * as Styled from './styled'
import { ResponsiveDrawerItem } from './responsive-drawer-item'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

interface IResponsiveDrawerProps {
  activeMenu: boolean
  primaryMenuItems: INestableMenuItem[]
  secondaryMenuItems: INestableMenuItem[]
  socialItems: ISocial[]
  toggleMenu: () => void
  logo: IResponsiveImage
  otherLanguageUrl: string
}

interface IMenuItem {
  name: string
  url: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nested?: any
}

export interface INestableMenuItem extends IMenuItem {
  subItems?: INestableMenuItem[]
}

export const ResponsiveDrawer: React.FC<IResponsiveDrawerProps> = ({
  activeMenu,
  primaryMenuItems,
  secondaryMenuItems,
  socialItems,
  toggleMenu,
  logo,
  otherLanguageUrl,
}: IResponsiveDrawerProps) => {
  let langName: string = ''

  if (API_SITE === 'BE') {
    langName = 'Français'
  } else if (API_SITE === 'FR') {
    langName = 'Nederlands'
  }

  const drawer = (
    <Styled.Wrapper>
      <Styled.List>
        {primaryMenuItems &&
          primaryMenuItems.map((item: INestableMenuItem, index: number) => (
            <ResponsiveDrawerItem key={index} item={item} toggleMenu={toggleMenu} />
          ))}
        {secondaryMenuItems &&
          secondaryMenuItems.map((item: INestableMenuItem, index: number) => (
            <ResponsiveDrawerItem key={index} item={item} toggleMenu={toggleMenu} />
          ))}
        {otherLanguageUrl && langName && (
          <ResponsiveDrawerItem
            item={{ url: otherLanguageUrl, name: langName }}
            toggleMenu={toggleMenu}
          />
        )}
      </Styled.List>
      <Styled.Bottom site={API_SITE}>
        <SearchBox />
        <SocialIcons items={socialItems} />
      </Styled.Bottom>
    </Styled.Wrapper>
  )
  return (
    <Styled.Drawer
      variant="temporary"
      anchor="top"
      open={activeMenu}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <Hidden lgUp>
        <Styled.LogoWrapper>
          <Logo logo={logo} size="small" />
          <Styled.CloseButton onClick={toggleMenu}>
            <Styled.CloseIcon />
          </Styled.CloseButton>
        </Styled.LogoWrapper>
        {drawer}
      </Hidden>
    </Styled.Drawer>
  )
}

export default ResponsiveDrawer
