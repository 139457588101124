import * as Styled from './styled'
import Link from 'next/link'
import { getHrefOfSlug } from '@Lib'
import moment from 'src/local_Packages/moment/moment'

interface IBlogRecommendationsProps {
  latestArticles: IBlogRecommendation[]
  title: string
}

interface IBlogRecommendation {
  name: string
  publicationDate: string
  url: string
}

export const BlogRecommendations = ({ latestArticles, title }: IBlogRecommendationsProps) => {
  if (!latestArticles) return

  return (
    <Styled.Wrapper>
      <Styled.Title>{title}</Styled.Title>

      {latestArticles.map((item, i) => {
        const date = moment(item.publicationDate)
        return (
          <Styled.ItemWrapper>
            <Link href={getHrefOfSlug(item.url)} as={item.url} key={i}>
              <Styled.RelatedLink title={item.name}>{item.name}</Styled.RelatedLink>
            </Link>
            <Styled.Time>{date.format('DD MMMM YYYY')}</Styled.Time>
          </Styled.ItemWrapper>
        )
      }
      )}
    </Styled.Wrapper>
  )
}
