import { OverviewItem, Pagination } from '@Components'
import Grid from '@material-ui/core/Grid'
import { IOverviewItemProps } from '@Components'

export interface IOverviewListProps {
  items?: IOverviewItemProps[]
}

export const OverviewList = (props: IOverviewListProps) => {
  return (
    <section>
      <Grid container spacing={0}>
        {props.items &&
          props.items.map((itemProps: any, i: number) => {
            return (
              <Grid key={i} item xs={12} sm={6} md={3}>
                <OverviewItem {...itemProps} />
              </Grid>
            )
          })}
      </Grid>

      <Pagination totalPage={1} currentPage={1} />
    </section>
  )
}
