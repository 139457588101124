import { Dispatch } from 'redux'

import { BroodService } from '@Services'
import { ActionConsts } from '@Definitions'

export const SearchBoxActions = {
  Reset: () => ({
    type: ActionConsts.SearchBox.ResetReducer,
  }),

  FetchData: (searchTerm: string) => {
    return async (dispatch: Dispatch) => {
      const result = await BroodService.GetGeneralSearch(searchTerm)

      if (result && result.success) {
        dispatch(SearchBoxActions.SetData(result.data))
      }
    }
  },

  SetData: (data: any) => ({
    type: ActionConsts.SearchBox.SetData,
    payload: data,
  }),
}
