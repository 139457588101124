import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: #f0efea;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
`

export const ResetLink = styled.button`
background: transparent;
  border: 0;
  color: #727272;
  cursor: pointer;
  font-size: 14px;
  outline: 0;
  text-decoration: none;
  &:hover {
    text-decoration:underline;
  }
`
