import React from 'react'
import Link from 'next/link'

import { IResponsiveImage } from '@Interfaces'
import { getHrefOfSlug } from '@Lib'

import * as Styled from './styled'

interface ILogoProps {
  logo: IResponsiveImage
  size?: string
  transparent?: boolean
}

export const Logo = ({ logo, size, transparent }: ILogoProps) => {
  return (
    <Link href={getHrefOfSlug('/')} as="/">
      <Styled.Logo url={logo && logo.desktopUrl} size={size} transparent={transparent} />
    </Link>
  )
}
