import React from 'react'
import getConfig from 'next/config'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { IStore } from '@Interfaces'
import { TranslationKeys } from '@Definitions'
import { getTranslator } from '@Redux/dictionary/selectors'

import * as Styled from './styled'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

interface IIngredientsProps {
  ingredients: string[]
}

export const Ingredients = ({ ingredients }: IIngredientsProps) => {
  const isNotNL = ['BE', 'FR'].indexOf(API_SITE) > -1
  const t = useSelector((state: IStore) => getTranslator(state.dictionary))

  return (
    <Styled.Wrapper isNotNL={isNotNL}>
      <Styled.Title>
        <Typography component="h2" variant="h2">
          {t(TranslationKeys.IngredientsTitle)}
        </Typography>
      </Styled.Title>
      <Styled.Content dangerouslySetInnerHTML={{ __html: ingredients }} />
    </Styled.Wrapper>
  )
}
