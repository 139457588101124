import Link from 'next/link'
import getConfig from 'next/config'
import { useSelector } from 'react-redux'
import { getTranslator } from '@Redux/dictionary/selectors'
import { getActiveSearchCategory, getGeneralSearchTerm } from '@Redux/general/selectors'
import { IStore } from '@Interfaces'

import * as Styled from './styled'
import { TranslationKeys } from '@Definitions';

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

export interface ISearchFilter {
  categories: ISearchCategory[]
  resultsCategories: any
}

export interface ISearchCategory {
  id: number
  title: string
  resultsCount: number
  active: boolean
}

export const SearchFilter = (searchCategories: ISearchFilter) => {
  const t = useSelector((state: IStore) => getTranslator(state.dictionary))
  const activeCategories = useSelector((state: IStore) => getActiveSearchCategory(state.general))
  const filterOn = searchCategories.categories ? searchCategories.categories.some(e => e.id.toString() === activeCategories) : []
  const searchTerm = useSelector((state: IStore) => getGeneralSearchTerm(state.general))
  const total = searchCategories.resultsCategories.find((e: any) => e.id === 0)

  const searchString = API_SITE === 'FR' ? '/recherche' : '/zoeken'

  return (
    <Styled.Wrapper>
      <Styled.Label>{t(TranslationKeys.SearchFilterResults)}</Styled.Label>
      <Styled.Category key="All_results" active={!filterOn}>
        <Link href={`/search?searchTerm=${searchTerm}`} as={`${searchString}?searchTerm=${searchTerm}`}>
          <a>{t(TranslationKeys.SearchCategoryAll)}{' '}</a>
        </Link>
        {total && (
          <Styled.Amount active={!filterOn}>{total.resultsCount}</Styled.Amount>
        )}
      </Styled.Category>
      {searchCategories && searchCategories.categories &&
        searchCategories.categories.map((item, i) => {
          const newCat = searchCategories.resultsCategories && searchCategories.resultsCategories.find((e: any) => e.id === item.id)
          item.active = item.id.toString() === activeCategories ? true : false

          return (
            <Styled.Category key={i} active={item.active}>
              <Link href={`/search?searchTerm=${searchTerm}&categories=${item.id}`} as={`${searchString}?searchTerm=${searchTerm}&categories=${item.id}`}>
                <a>{item.title}{' '}</a>
              </Link>
              {newCat && (
                <Styled.Amount active={item.active}>{newCat.resultsCount}</Styled.Amount>
              )}
            </Styled.Category>
          )
        })}
    </Styled.Wrapper>
  )
}
