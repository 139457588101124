import styled, { AnyStyledComponent } from 'styled-components'

export const SocialIconsWrapper: AnyStyledComponent = styled.li`
  ${({ theme }: any) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;

    ${theme.breakpoints.up('lg')} {
      max-width: 300px;
      justify-content: space-between;
      padding: 0 10px;
    }
  `}
`

export const SocialIcon: AnyStyledComponent = styled.div`
  ${({ theme, site }: any) => `
    color: ${['FR', 'BE'].indexOf(site) > -1 ? '#434240' : 'white'};

    &:hover {
      cursor: pointer;
    }

    svg {
      height: 30px;
      padding-left: 10px;
      padding-right: 10px;

      ${theme.breakpoints.up('lg')} {
        height: 16px;
        padding-left: 7px;
        padding-right: 7px;
      }
    }
  `}
`
