import styled, { AnyStyledComponent } from 'styled-components'

export const SearchWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 5px;

    ${theme.breakpoints.up('lg')} {
      background-color: transparent;
      max-width: 300px;
    }
  `}
`

export const SearchForm = styled.form`
  flex: 1 1 auto;
  position: relative;
`

export const SearchIcon: AnyStyledComponent = styled.div`
  ${({ theme, site }: any) => `
    display:none;
    
    ${theme.breakpoints.up('lg')} {
      display: block;
      width: 18px;
      height: 18px;
      background-image: url(/images/searchIcon${['BE', 'FR'].indexOf(site) > -1 ? 'Dark' : ''}.svg);
      background-size: 18px 18px;
      position: absolute;
      top: 15px;
      left: 10px;
    }

`}
`

export const SearchInput: AnyStyledComponent = styled.input`
  ${({ theme, visible, site }: any) => `
    color: ${['BE', 'FR'].indexOf(site) > -1 ? '#333333' : '#ffffff'};
    width: 100%;
    line-height: 28px;
    height: 44px;
    border: none;
    font-size: 15px;
    background-color: #dbcec6;
    padding: 5px 5px 5px 30px;

    ${theme.breakpoints.up('lg')} {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 3px;
      caret-color: white;
      height: 35px;
      line-height: 25px;
      ${visible ? 'display: block;' : 'display: none;'}
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${['BE', 'FR'].indexOf(site) > -1 ? '#333333' : '#ffffff'};
    }
  `}
`
