import React from 'react'
import Grid from '@material-ui/core/Grid'

import { HighlightBlockProf, IHighlightBlockProfProps } from '@Components'

import * as Styled from './styled'

export interface IHighlightBlockListProfProps {
  coloredBlocks?: IHighlightBlockProfProps[]
  amountRow?: number
}

export const HighlightBlockListProf = (props: IHighlightBlockListProfProps) => {
  return (
    <Styled.Wrapper>
      <Grid container>
        {props.coloredBlocks &&
          props.coloredBlocks.map((itemProps: any, i: number) => {
            return (
              <Grid
                key={i}
                item
                xs={12}
                sm={6}
                md={
                  props.coloredBlocks && props.coloredBlocks.length === 2
                    ? 6
                    : props.amountRow === 4
                    ? 3
                    : 4
                }
              >
                <HighlightBlockProf
                  {...itemProps}
                  index={i}
                  isLastIndex={(props.coloredBlocks || []).length - 1 === i}
                />
              </Grid>
            )
          })}
      </Grid>
    </Styled.Wrapper>
  )
}
