import styled, { css, AnyStyledComponent } from 'styled-components'

import { extraColors } from '@Themes'

export const Wrapper: AnyStyledComponent = styled.div`
  ${({ isNotNL }: any) => css`
    background-color: ${isNotNL ? extraColors.yellow[0] : 'rgba(183, 0, 64, 0.9)'};
    border-radius: 3px;
    color: #fff;
    margin: 0 0 20px;
    padding: 20px;
  `}
`

export const Title: AnyStyledComponent = styled.div`
  padding-bottom: 10px;

  h2 {
    font-size: 34px;
    line-height: 34px;
  }
`

export const Content: AnyStyledComponent = styled.div`
  ul {
    list-style: none outside;
    margin: 0;
    padding: 0;
  }

  font-family: 'Melbourne', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
`
