import React, { useState } from 'react'
import getConfig from 'next/config'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import { FormControl, Typography } from '@material-ui/core'
import Recaptcha from 'react-recaptcha'

import { IStore } from '@Interfaces'
import { BroodService } from '@Services'
import { TranslationKeys } from '@Definitions'
import { getTranslator } from '@Redux/dictionary/selectors'

import { ISubmit } from '../ContactForm'
import * as Styled from './styled'

const {
  publicRuntimeConfig: { RECAPTCHA_SITE_KEY },
} = getConfig()

interface IFormItemProps {
  required?: boolean
  include?: boolean
}

interface IFormProps {
  name?: IFormItemProps
  email?: IFormItemProps
  message?: IFormItemProps
}

interface IContactFormProf {
  formProps?: IFormProps
}

const FormTextField = (props: {
  name: string
  placeholder: string
  touched: any
  errors: any
  change: any
  blur: any
  values: any
  initialValues: any
  multiline?: boolean
  inputProps?: any
}) => {
  const {
    name,
    multiline,
    placeholder,
    touched,
    errors,
    change,
    blur,
    values,
    initialValues,
    ...rest
  } = props
  return (
    <>
      <Styled.Label htmlFor={name}>{placeholder}:</Styled.Label>
      <Styled.TextField
        name={name}
        fullWidth
        variant="outlined"
        required
        placeholder=""
        value={values[name]}
        defaultValue={initialValues[name]}
        error={touched[name] && Boolean(errors[name])}
        onChange={change.bind(null)}
        onBlur={blur.bind(null, name)}
        multiline={multiline}
        {...rest}
      />
    </>
  )
}

export const ContactFormProf = (props: IContactFormProf) => {
  const { formProps = {} } = props
  const [successMessage, setMessage] = useState('')
  const [errorMessage, setError] = useState('')
  const t = useSelector((state: IStore) => getTranslator(state.dictionary))

  const [loading, setLoading] = useState(false)

  const { name = {}, email = {}, message = {} } = formProps as IFormProps

  const initialValues = {
    name: '',
    email: '',
    message: '',
    recaptcha: '',
  }

  const onSubmit: ISubmit = async (data, hooks) => {
    setLoading(true)

    const { name, email, message } = data

    const formData = {
      name,
      email,
      message,
    }

    const request = BroodService.PostContactFormProf(formData)
    const response = await request

    setLoading(false)

    if (response && response.error) {
      setMessage('')
      setError(t(TranslationKeys.ContactFormErrorMessage))
    } else {
      setMessage(t(TranslationKeys.ContactFormSuccesMessage))
      setError('')
      hooks.resetForm()
    }
  }

  return (
    <Styled.Wrapper id="form">
      {successMessage && (
        <>
          <Styled.Title component="h3" variant="h3">
            {t(TranslationKeys.ContactFormSendTitle)}
          </Styled.Title>
          <Styled.SuccesMessage>
            {successMessage}
          </Styled.SuccesMessage>
        </>
      )}

      {!successMessage && (
        <Formik
          initialValues={initialValues}
          onSubmit={(values: any, hooks: any) => {
            return onSubmit(
              {
                ...values,
              },
              hooks,
              {
                setLoading,
              },
            )
          }}
          validate={values => {
            const errors: any = {}
            if (!values.name) {
              errors.name = t(TranslationKeys.FormRequired)
            }
            if (!values.email) {
              errors.email = t(TranslationKeys.FormRequired)
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = t(TranslationKeys.EmailaddressInvalid)
            }
            if (!values.message) {
              errors.message = t(TranslationKeys.FormRequired)
            }
            if (!values.recaptcha) {
              errors.recaptcha = t(TranslationKeys.FormRequired)
            }

            return errors
          }}
        >
          {props => {
            const {
              handleChange,
              handleSubmit,
              setFieldValue,
              errors,
              values,
              setFieldTouched,
              touched,
            } = props

            const blur = (name: any) => {
              setFieldTouched(name, true, true)
            }

            const change = (e: any) => {
              e.persist()
              handleChange(e)
            }

            // const hasTouchedFields = touched && Object.keys(touched).length > 0
            return (
              <form onSubmit={handleSubmit} noValidate>
                <FormControl fullWidth>
                  <FormTextField
                    values={{ ...values }}
                    errors={{ ...errors }}
                    placeholder={t(TranslationKeys.FormName)}
                    name="name"
                    blur={blur}
                    change={change}
                    touched={{ ...touched }}
                    initialValues={initialValues}
                    inputProps={{ maxLength: '128', ...name }}
                  />
                  {touched.name && errors.name && (
                    <Typography component="p" variant="body1" color="error" gutterBottom>
                      {errors.name}
                    </Typography>
                  )}
                </FormControl>
                <FormControl fullWidth>
                  <FormTextField
                    values={{ ...values }}
                    errors={{ ...errors }}
                    placeholder={t(TranslationKeys.FormEmail)}
                    name="email"
                    blur={blur}
                    change={change}
                    touched={{ ...touched }}
                    initialValues={initialValues}
                    inputProps={{ maxLength: '128', ...email }}
                  />
                  {touched.email && errors.email && (
                    <Typography component="p" variant="body1" color="error" gutterBottom>
                      {errors.email}
                    </Typography>
                  )}
                </FormControl>
                <FormControl fullWidth>
                  <FormTextField
                    values={{ ...values }}
                    errors={{ ...errors }}
                    placeholder={t(TranslationKeys.ContactformProfMessage)}
                    name="message"
                    multiline
                    blur={blur}
                    change={change}
                    touched={{ ...touched }}
                    initialValues={initialValues}
                    inputProps={{ rows: 5, maxLength: '1000', ...message }}
                  />
                </FormControl>
                {touched.message && errors.message && (
                  <Typography component="p" variant="body1" color="error" gutterBottom>
                    {errors.message}
                  </Typography>
                )}
                {typeof window !== 'undefined' && (
                  <Recaptcha
                    sitekey={RECAPTCHA_SITE_KEY}
                    verifyCallback={(response: any) => {
                      setFieldValue('recaptcha', response)
                    }}
                  />
                )}
                <br />
                {errors.recaptcha && touched.recaptcha && <p>{errors.recaptcha}</p>}
                <Styled.Button
                  disabled={loading}
                  variant="contained"
                  fullWidth
                  type="submit"
                  color="primary"
                >
                  {t(TranslationKeys.ContactformProfButton)}
                </Styled.Button>
              </form>
            )
          }}
        </Formik>
      )}

      {errorMessage && (
        <Typography component="p" variant="body1" color="error" align="center">
          {errorMessage}
        </Typography>
      )}
    </Styled.Wrapper>
  )
}
