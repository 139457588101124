import Grid from '@material-ui/core/Grid'
import { HighlightBlock, IHighlightBlockProps } from '@Components'

export interface IHighlightBlockListProps {
  blocks?: IHighlightBlockProps[]
  amountRow?: number
}

export const HighlightBlockList = (props: IHighlightBlockListProps) => {
  return (
    <Grid container spacing={3}>
      {props.blocks &&
        props.blocks.map((itemProps: any, i: number) => {
          return (
            <Grid key={i} item xs={12} sm={6} md={props.blocks && props.blocks.length === 2 ? 6 : props.amountRow === 4 ? 3 : 4}>
              <HighlightBlock {...itemProps} />
            </Grid>
          )
        })}
    </Grid>
  )
}

