import { INITIAL_STATE } from '@Redux/recipes/reducer'

export const getRecipeFilters = (state = INITIAL_STATE) => state && state.filters
export const getRecipeSearchTerm = (state = INITIAL_STATE) => state && state.searchTerm
export const getRecipes = (state = INITIAL_STATE) => state && state.recipes

export default {
  getRecipeFilters,
  getRecipeSearchTerm,
  getRecipes,
}
