import styled from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => `
    margin-bottom: 20px;

    h2 {
      color: ${theme.palette.primary.dark};
      font-family: 'Melbourne', sans-serif;
      font-size: 24px;
      font-weight: normal;
      line-height: 28px;
      margin-bottom: 20px;
    }
  `}
`
export const Items = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -10px;
`
export const Item = styled.div`
  ${({ theme }) => `
    box-sizing: border-box;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 10px 20px;

    a {
      color: ${theme.palette.secondary.light};
      font-family: 'Melbourne', sans-serif;
      font-size: 20px;
      line-height: 20px;
      font-weight: normal;
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }
    }
    
    ${theme.breakpoints.up('sm')} {
      flex: 0 0 50%;
      max-width: 50%;
    }

    ${theme.breakpoints.up('md')} {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
  `}
`

export const NoItems = styled.p`
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 14px;
`

export const ImageWrapper = styled.div`
  margin-bottom: 10px;
`
export const Image = styled.img`
  border-radius: 3px;
  display: block;
  height: auto;
  width: 100%;
`
export const ContentWrapper = styled.div`
  ${({ theme }) => `
    h1 {
      color: ${theme.palette.primary.dark};
      font-size: 20px;
      line-height: 22px;
      font-family: 'Melbourne', sans-serif;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
  `}
`
