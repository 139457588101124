import styled, { AnyStyledComponent } from 'styled-components'
import {
  List as MuiList,
  ListItem as MuiListItem,
  Drawer as MuiDrawer,
  IconButton as MuiIconButton,
} from '@material-ui/core'

export const Wrapper = styled.div`
  background-color: #fff;
  position: relative;
  z-index: 100;
`

export const Drawer = styled(MuiDrawer)`
  width: 100%; 
  .isExternalLink {
		padding: 0px !important;
	}
	
  .externalLink {
	    padding: 15px 20px 12px !important;
		width: 100%;
	}	
`

export const Bottom: AnyStyledComponent = styled.div`
  ${({ theme, site }: any) => `
    width: 100%;
    margin-bottom: 0;
    padding: 10px;
    background: ${site === 'BE' ? theme.palette.secondary.dark : theme.palette.secondary.main}
  `}
`

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CloseButton = styled(MuiIconButton)`
  &:hover {
    background-color: transparent;
  }
`
export const CloseIcon = styled.span`
  background-image: url(/images/icons/close.svg);
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  width: 44px;
`

export const List = styled(MuiList)`
  list-style: none outside;
  margin: 0;
  padding-bottom: 20px;
  padding-left: 0;
  width: 100%;
`.withComponent('ul')

export const Arrow: AnyStyledComponent = styled.span`
  ${({ open }: any) => `
    background-image: url(/images/icons/arrow-up.svg);
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    height: 20px;
    transform: rotate(${open ? `0deg` : '-180deg'});
    transition: transform .25s ease-in-out;
    vertical-align:middle;
    width: 20px;
  `}
`

export const ListItem: AnyStyledComponent = styled(MuiListItem)`
  ${({ nested }: any) => `
    display: flex;
    width: 100%;
    background-color: #fff;
    ${
      nested
        ? `
      padding: 0 20px;
    `
        : `
      border-top: 1px solid #e5e5e5;
      padding: 15px 20px 12px;
    `
    }
  `}
  a {
    text-decoration: none;
  }
`.withComponent('li')

export const ListItemText: AnyStyledComponent = styled.div`
  ${({ nested, theme }: any) => `
    color: ${nested ? '#501310' : `${theme.palette.primary.main}`};
    font-size: 20px;
    flex: 1 1 auto;
    line-height: 1;
    margin: 0;
    text-decoration: none;
  `}
`
