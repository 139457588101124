import React from 'react'
import {filter, find} from 'lodash'
import getConfig from 'next/config'
import { Container, Grid } from '@material-ui/core'

import { IMetaData } from '@Interfaces'
import {
  Header,
  Banner,
  BlockSplitter,
  FAQSearch,
  RecipeSearch,
  HeroSearch,
  Footer,
  Tags,
  Breadcrumbs,
  ProfessionalPopup,
  HighlightBlockList,
  ContactForm,
  ContactFormProf,
} from '@Components'
import { IntlProvider } from '@Containers'
import * as Styled from './styled'
import LandingPage from '@Components/LandingPage'

interface ISimpleTemplateProps {
  meta: IMetaData
  blocks?: any
  showSocialLinks?: boolean
  sidebarMenuTitle?: string
  sidebarMenuItems?: any[]
  breadcrumbs?: any[]
  query?: string
  objectKind?: string
  otherLanguageUrl: string
}

const {
  publicRuntimeConfig: { IS_PROFESSIONAL },
} = getConfig()

export const SimpleTemplate = ({
  meta,
  blocks,
  objectKind,
  breadcrumbs,
  query,
  otherLanguageUrl,
}: ISimpleTemplateProps) => {
  const { banner, main, aside } = blocks || { banner: null, main: [], aside: null }
  const bannerBlock = banner && banner.length > 0 ? banner[0] : null
  const bannerMainBlocks =
    main && main.length > 0
      ? filter(
          main,
          block =>
            objectKind !== 'UMBCAMPAIGN' &&
            (block.objectKind === 'UMBHEROBLOCK' ||
              block.objectKind === 'UMBHEROBLOCKMEDIA' ||
              block.objectKind === 'UMBHEROSEARCHBLOCK' ||
              block.objectKind === 'UMBHEROSEARCHRECIPEBLOCK'),
        )
      : null
  let highlightBlocks: any = null

  const tags =
    main && main.length > 0 ? find(main, { objectKind: 'UMBTAGSANDSOCIALLINKSBLOCK' }) : null
  const isRecipePage = main && main.length > 0 ? find(main, { objectKind: 'RECIPESBLOCK' }) : null
  // check if recipe detail page
  const isRecipeDetailPage = objectKind === 'UMBRECIPEDETAIL'
  const isContactPage = objectKind === 'UMBPROFESSIONALSCONTACTPAGE'
  const sdItemScope = isRecipeDetailPage
    ? { itemScope: true, itemType: 'https://schema.org/Recipe' }
    : {}

  if (isRecipeDetailPage && main && main.length > 0) {
    highlightBlocks = find(main, { objectKind: 'UMBHIGHLIGHTEDBLOCK' })
  }

  const renderBlocks = (isCampaign: boolean = false) => (
    <>
      {IS_PROFESSIONAL === 'true' && <ProfessionalPopup />}
      <Grid item xs={12} md={aside && aside.length > 0 ? 8 : 12}>
        {breadcrumbs && <Breadcrumbs isCampaign={isCampaign} breadcrumbs={breadcrumbs} />}
        <BlockSplitter isCampaign={isCampaign} blocks={main} query={query} pageKind={objectKind} />
        {tags && <Tags {...tags} meta={meta} />}
        {isRecipeDetailPage && highlightBlocks && (
          <HighlightBlockList {...highlightBlocks} meta={meta} />
        )}
        {isContactPage ? IS_PROFESSIONAL === 'true' ? <ContactFormProf /> : <ContactForm /> : null}
      </Grid>

      {aside && aside.length > 0 && (
        <Styled.AsideGrid higher={false} item xs={12} md={4}>
          <BlockSplitter blocks={aside} />
        </Styled.AsideGrid>
      )}
    </>
  )

  return (
    <Styled.Wrapper {...sdItemScope}>
      <IntlProvider>
        <Header otherLanguageUrl={otherLanguageUrl} />
        {bannerBlock &&
          (isRecipePage ? (
            <RecipeSearch {...bannerBlock} />
          ) : bannerBlock.objectKind === 'UMBHEROSEARCHFAQBLOCK' ? (
            <FAQSearch {...bannerBlock} />
          ) : bannerBlock.objectKind === 'UMBHEROSEARCHBLOCK' ? (
            <HeroSearch {...bannerBlock} />
          ) : (
            <Banner {...bannerBlock} />
          ))}
        {objectKind === 'UMBHOME' && !bannerMainBlocks ? (
          <LandingPage />
        ) : objectKind === 'UMBCAMPAIGN' ? (
          <main>{renderBlocks(true)}</main>
        ) : (
          <Container component="main">
            <Grid container spacing={3}>
              {renderBlocks()}
            </Grid>
          </Container>
        )}

        {bannerMainBlocks &&
          bannerMainBlocks.map((mainBlock: any, index: number) => {
            const notTop = bannerMainBlocks.length === index + 1 ? false : true
            if (mainBlock.objectKind === 'UMBHEROSEARCHBLOCK') {
              return <HeroSearch {...mainBlock} notTop={notTop} isCampaign={true} />
            } else if (
              mainBlock.objectKind === 'UMBRECIPESEARCHBLOCK' ||
              mainBlock.objectKind === 'UMBHEROSEARCHRECIPEBLOCK'
            ) {
              return <RecipeSearch {...mainBlock} notTop={notTop} isCampaign={true} />
            } else if (mainBlock.objectKind === 'UMBHEROBLOCK') {
              return <Banner {...mainBlock} notTop={notTop} />
            } else if (mainBlock.objectKind === 'UMBHEROBLOCKMEDIA') {
              return <Banner {...mainBlock} notTop={notTop} />
            }
          })}
        <Footer />
      </IntlProvider>
    </Styled.Wrapper>
  )
}
