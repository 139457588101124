import theme from '@Themes'
import styled, { css } from 'styled-components'

const HeroBannerDetailComponent = styled.section<{
  secondaryColor?: string
  backgroundColor?: string
}>`
  position: relative;
  font-family: 'atkinson', serif;
  text-align: center;
  color: ${({ secondaryColor }) => secondaryColor};
  background-color: ${({ backgroundColor }) => backgroundColor};

  h3 {
    font-size: 20px;
    margin-top: 10px;
    &::before,
    &:after {
      content: '-';
      margin: 0 5px;
    }
    ${theme.breakpoints.up('lg')} {
      font-size: 20px;
      margin: 20px auto 0;
    }
  }

  p {
    position: relative;
    font-size: 18px;
    max-width: 500px;
    text-align: left;
    margin: 50px auto 0;
    ${theme.breakpoints.up('lg')} {
      font-size: 16px;
      margin: 50px auto 0;
    }
  }
`
const HeroBannerDetailContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 20px 50px;
  max-width: 1030px;
  margin: -70px auto 0;
  overflow: hidden;
  ${theme.breakpoints.up('lg')} {
    margin: 0 auto;
    flex-direction: row-reverse;
  }
`

const HeroBannerDetailImage = styled.div<{ image?: string }>`
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${theme.breakpoints.up('lg')} {
    max-width: 800px;
  }

  img {
    width: 100%;
    object-fit: fill;
    object-position: center;
    ${theme.breakpoints.down('md')} {
      margin: ${({ image }) => (image === 'BroodKleur' ? '100px 0' : 'initial')};
    }
    ${theme.breakpoints.up('md')} {
      margin-left: ${({ image }) => (image === 'BroodKleur' ? '100px' : 'initial')};
    }
  }
`

const HeroBannerDetailTitle = styled.h1<{
  fontFamily?: string
  fontSizeM?: string
  fontheightM: string
}>`
  max-width: 500px;
  margin: -100px auto 0;
  font-size: ${({ fontSizeM }) => fontSizeM};
  line-height: ${({ fontheightM }) => fontheightM};
  ${({ fontFamily }) =>
    css`
      font-family: ${fontFamily};
    `}
  ${theme.breakpoints.up('lg')} {
    font-size: 130px;
    margin: 0 auto;
    padding: 50px 0 0;
    line-height: ${({ fontheightM }) => fontheightM};
  }
`
const HeroBannerDetailIconMobile = styled.div`
  position: absolute;
  left: -20px;
  top: -35px;
  ${theme.breakpoints.up('sm')} {
    display: none;
  }
`

const HeroBannerDetailIcon = styled.div`
  display: none;
  position: absolute;
  right: 14px;
  bottom: 40px;
  transform: rotate(180deg) scale(1.3);
  ${theme.breakpoints.up('md')} {
    display: block;
  }
`

export {
  HeroBannerDetailComponent,
  HeroBannerDetailImage,
  HeroBannerDetailTitle,
  HeroBannerDetailContent,
  HeroBannerDetailIconMobile,
  HeroBannerDetailIcon,
}
