import styled, { AnyStyledComponent } from 'styled-components'
import { Typography } from '@material-ui/core'

export const List = styled.ul`
  list-style: none outside;
  margin-bottom: 14px;
  padding-left: 0;
`
export const ListItem = styled.li`
  padding-bottom: 14px;
`

export const ListItemTitle: AnyStyledComponent = styled(Typography)`
  ${({ theme, site }: any) => `
    a {
      color: ${
        ['BE', 'FR'].indexOf(site) > -1
          ? theme.palette.secondary.light
          : theme.palette.primary.light
      };
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  `}
`
export const ListItemDate: AnyStyledComponent = styled.time`
  font-weight: bold;
`
