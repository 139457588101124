import React from 'react'
import { Typography } from '@material-ui/core'
import getConfig from 'next/config'

import { CustomLink, Youtube } from '@Components'
import { IResponsiveImage } from '@Interfaces'

import * as Styled from './styled'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

interface IColorBlockProps {
  colorTheme: string
  title: string
  intro: string
  link?: string
  image: IResponsiveImage
  youtubeEmbeddedCode: string
  linkTarget: string
}

export const ColorBlock = ({
  colorTheme,
  title,
  intro,
  link,
  image,
  youtubeEmbeddedCode,
  linkTarget,
}: IColorBlockProps) => {
  const renderImage = (title: string, image: IResponsiveImage) => {
    if (!image) return null

    return (
      <Styled.Image
        width="260"
        height="130"
        alt={title}
        src={image && image.desktopUrl}
        srcSet={`${image && image.mobileUrl ? image.mobileUrl + ' 1x,' : ''} ${
          image && image.desktopUrl ? image.desktopUrl + '  2x' : ''
        }`}
      />
    )
  }

  let belgiumVersion = false

  if (API_SITE === 'BE') {
    belgiumVersion = true
  }

  return (
    <Styled.Wrapper color={colorTheme} belgium={belgiumVersion}>
      {title && (
        <Styled.Title color={colorTheme} belgium={belgiumVersion}>
          {link ? (
            <CustomLink target={linkTarget} url={link}>
              <Typography component="h3" variant="h3">
                {title}
              </Typography>
            </CustomLink>
          ) : (
            <Typography component="h3" variant="h3">
              {title}
            </Typography>
          )}
        </Styled.Title>
      )}
      {intro && <Styled.Content dangerouslySetInnerHTML={{ __html: intro }} />}
      {link && !youtubeEmbeddedCode && (
        <CustomLink target={linkTarget} url={link}>
          {renderImage(title, image)}
        </CustomLink>
      )}
      {!link && !youtubeEmbeddedCode && renderImage(title, image)}
      {youtubeEmbeddedCode && <Youtube url={youtubeEmbeddedCode} />}
    </Styled.Wrapper>
  )
}
