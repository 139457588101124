import React from 'react'
import Link from 'next/link'
import getConfig from 'next/config'

import { ContactForm } from '@Components'

import * as Styled from './styled'

interface IFAQList {
  questions: IQuestion[]
}

interface IQuestion {
  name?: string
  url: string
}

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

export const FAQList = (props: IFAQList) => {
  const isNotNL = ['BE', 'FR'].indexOf(API_SITE) > -1

  return (
    <Styled.Wrapper>
      <Styled.List>
        {props &&
          props.questions.map((question: IQuestion, i) => (
            <Styled.ListItem key={i} isNotNL={isNotNL}>
              <Link href={question.url} passHref>
                <a>{question.name}</a>
              </Link>
            </Styled.ListItem>
          ))}
      </Styled.List>

      <ContactForm />
    </Styled.Wrapper>
  )
}
