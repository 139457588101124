import styled, { AnyStyledComponent } from 'styled-components'
import { Button as MuiButton, InputBase } from '@material-ui/core'
import { Paper } from '@material-ui/core'

export const Input = styled(InputBase)`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    input {
      border-radius: 5px 5px 0px 0px;
      background: #fbfbfb;
      box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
      border: none;
      box-sizing: border-box;
      display: inline-block;
      flex: 1 1 auto;
      font-size: 22px;
      color: #999;
      padding: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      vertical-align: top;

      ${theme.breakpoints.up('md')} {
        border-radius: 5px 0px 0px 5px;
      }
    }
  `}
`

export const Button: AnyStyledComponent = styled(MuiButton)`
  ${({ theme }) => `
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 27px;
    font-family: 'Melbourne', sans-serif;
    line-height: 1.25;
    text-align: center;
    text-transform: none;
    vertical-align: top;
    width: 100%;
    border-radius: 0px 0px 4px 4px;

    ${theme.breakpoints.up('md')} {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      flex: 0 0 110px;
    }
  `}
`

export const PaperWrapper: AnyStyledComponent = styled(Paper)`
${({ theme }) => `
    position: relative; 
    display: flex;

    ${theme.breakpoints.down('sm')} {
      display: block;
      
      #searchbox-autosuggest {
        height: 44px;
      }
    }
  `}
`