import styled from 'styled-components'

const MediaBlockSection = styled.section`
  margin: 0 auto;
  max-width: 1030px;
`

const MediaBlockImage = styled.figure`
  max-height: 500px;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  margin: 0;
  img {
    width: 100%;
    object-fit: fill;
    object-position: center;
  }
`

export { MediaBlockSection, MediaBlockImage }
