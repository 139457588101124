import React, { useState } from 'react'
import getConfig from 'next/config'
import { useSelector } from 'react-redux'
import Router from 'next/router'

import { getTranslator } from '@Redux/dictionary/selectors'
import { TranslationKeys } from '@Definitions'
import { IStore } from '@Interfaces'

import * as Styled from './styled'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

export const SearchBox = () => {
  const [showSearch, setShowSearch] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const t = useSelector((state: IStore) => getTranslator(state.dictionary))

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    if (searchTerm && searchTerm.length > 1) {
      const searchString = API_SITE === 'FR' ? 'recherche' : 'zoeken'
      const as = `/${searchString}?searchTerm=${searchTerm}`

      window.scrollTo(0, 0)
      Router.push(`/search?searchTerm=${searchTerm}`, as)
    }
  }

  return (
    <Styled.SearchForm onSubmit={handleSubmit}>
      <Styled.SearchWrapper onClick={() => setShowSearch(true)}>
        <Styled.SearchIcon site={API_SITE} />
        <Styled.SearchInput
          site={API_SITE}
          placeholder={t(TranslationKeys.MainSearchLabel)}
          visible={showSearch}
          onChange={(e: any) => setSearchTerm(e.target.value)}
        />
      </Styled.SearchWrapper>
    </Styled.SearchForm>
  )
}
