import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import cookie from 'react-cookies'
import { Backdrop, Modal } from '@material-ui/core'
import { TranslationKeys } from '@Definitions'
import { getTranslator } from '@Redux/dictionary/selectors'
import { getPopupContent } from '@Redux/general/selectors'
import * as Styled from './styled'

import { IStore } from '@Interfaces'

export const ProfessionalPopup = () => {
  const t = useSelector((state: IStore) => getTranslator(state.dictionary))
  const popupContent = useSelector((state: IStore) => getPopupContent(state.general))
  const [open, setOpen] = useState(true)
  const [check, setCheck] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (cookie.load('professionalDisclaimer') === 'true') {
      setCheck(true)
      handleClose()
    } else {
      setCheck(false)
    }
  }, [])

  const handleSubmit = (e: any) => {
    
    if (check) {
      cookie.save('professionalDisclaimer', 'true', {
        path: '/',
      })
      handleClose()
    }
    e.preventDefault()
  }

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        disableBackdropClick
        disableEscapeKeyDown
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{ zIndex: 100000 }}
      >
        <Styled.Modal>
          <form onSubmit={handleSubmit}>
            <Styled.Text dangerouslySetInnerHTML={{ __html: popupContent.content }} />
            <Styled.DisclaimerWrapper>
              <input
                type="checkbox"
                name="role"
                onChange={(e: any) => setCheck(e.target.checked)}
              />
              <label htmlFor="role">{popupContent.yesLabel}</label>
            </Styled.DisclaimerWrapper>
            <Styled.ChoiceWrapper>
              <Styled.Button variant="contained" fullWidth type="submit" color="primary">
                {t(TranslationKeys.ProfessionalPopupButton)}
              </Styled.Button>
              <Styled.DeclineLink href={popupContent.noLink}>
                {popupContent.noLabel}
              </Styled.DeclineLink>
            </Styled.ChoiceWrapper>
          </form>
        </Styled.Modal>
      </Modal>
    </div>
  )
}
