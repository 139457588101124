import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import Router from 'next/router'
import getConfig from 'next/config'

import { Container } from '@Components'
import { IResponsiveImage, IStore } from '@Interfaces'
import { GeneralActions } from '@Actions'
import { getGeneralSearchTerm } from '@Redux/general/selectors'

import * as Styled from './styled'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

interface IHeroSearchProps {
  image?: IResponsiveImage
  bigHero: boolean
  ctaButtonText?: string
  searchfieldPlaceholder?: string
  title?: string
  notTop?: boolean
}

export const HeroSearch = ({
  image,
  bigHero,
  ctaButtonText,
  searchfieldPlaceholder,
  title,
  notTop,
}: IHeroSearchProps) => {
  const [value, setValue] = useState('')
  const searchTermFromStore = useSelector((state: IStore) => getGeneralSearchTerm(state.general))
  const dispatch = useDispatch()

  useEffect(() => {
    setValue(searchTermFromStore)
  }, [searchTermFromStore])

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const onSubmitHandler = () => {
    dispatch(GeneralActions.SetGeneralSearchTerm(value))

    const as = `${API_SITE === 'FR' ? '/recherche' : '/zoeken'}?searchTerm=${value}`
    window.scrollTo(0, 0)
    Router.push(`/search?searchTerm=${value}`, as)
  }

  const onKeyPress = ({ key }: any) => {
    if (key === 'Enter') {
      onSubmitHandler()
    }
  }

  const size = bigHero ? 'large' : 'medium'

  return (
    <Styled.Wrapper
      imageDesktopUrl={image && image.desktopUrl}
      imageMobileUrl={image && image.mobileUrl}
      notTop={notTop}
    >
      <Container>
        <Styled.Inner size={size}>
          <Styled.Title
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Styled.FormWrapper>
            <Styled.Input
              type="text"
              value={value}
              placeholder={searchfieldPlaceholder}
              onChange={onChangeHandler}
              onKeyPress={onKeyPress}
            />
            <Styled.Button type="submit" onClick={onSubmitHandler}>
              {ctaButtonText}
            </Styled.Button>
          </Styled.FormWrapper>
        </Styled.Inner>
      </Container>
    </Styled.Wrapper>
  )
}
