import * as Styled from './styled'
import React, { useState } from 'react'
import { Backdrop, Modal } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { Youtube } from '@Components'
import { IResponsiveImage } from '@Interfaces'
import getConfig from 'next/config'

interface IBannerProps {
  title?: string
  subTitle?: string
  link: string
  youtubeEmbeddedCode: string
  ctaButtonText?: string
  image?: IResponsiveImage
  bigHero: boolean
  notTop?: boolean
  objectKind: string
}

const {
  publicRuntimeConfig: { UPLOADS_URL },
} = getConfig()

export const Banner = ({
  title,
  subTitle,
  link,
  youtubeEmbeddedCode,
  ctaButtonText,
  image,
  bigHero,
  notTop,
  objectKind
}: IBannerProps) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    if (youtubeEmbeddedCode && !ctaButtonText) {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  let center = ctaButtonText ? true : false
  const size = bigHero ? 'large' : 'medium'
  const canUseLink = link && !youtubeEmbeddedCode
  const download = (objectKind === 'UMBHEROBLOCKMEDIA')

  return (
    <>
      <Styled.Wrapper
        imageDesktopUrl={image && image.desktopUrl}
        imageMobileUrl={image && image.mobileUrl}
        size={size}
        hasVideo={youtubeEmbeddedCode && !ctaButtonText}
        onClick={() => handleOpen()}
        notTop={notTop}
      >
        <meta itemProp="image" content={image && image.desktopUrl} />
        <Styled.TextWrapper center={center}>
          {canUseLink && !ctaButtonText ? (
            <Styled.LinkButton>
              <a href={link || ''}></a>
            </Styled.LinkButton>
          ) : null}
          <Styled.InnerTextWrapper center={center}>
            {title && (ctaButtonText || !link) && (
              <Styled.Title
                center={center}
                itemProp="name"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            {title && link && !ctaButtonText && (
              <Styled.TitleLink
                center={center}
                href={link}
                itemProp="name"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            {subTitle && <Styled.SubTitle dangerouslySetInnerHTML={{ __html: subTitle }} />}
            {youtubeEmbeddedCode && !ctaButtonText ? (
              <Styled.VideoButton></Styled.VideoButton>
            ) : null}
            {center && ctaButtonText && link && download == false && (
              <Styled.CenterButtonWrapper href={link}>
                <Styled.CenterButton dangerouslySetInnerHTML={{ __html: ctaButtonText }} />
              </Styled.CenterButtonWrapper>
            )}
            {center && ctaButtonText && link && download == true && (
              <Styled.CenterButtonWrapper target='_blank' href={UPLOADS_URL + link.substring(0, link.length - 1)} download={link}>
                <Styled.CenterButton dangerouslySetInnerHTML={{ __html: ctaButtonText }} />
              </Styled.CenterButtonWrapper>
            )}
          </Styled.InnerTextWrapper>
        </Styled.TextWrapper>
      </Styled.Wrapper>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{ zIndex: 100000 }}
      >
        <Styled.Modal>
          <Styled.ModalCloseButton onClick={handleClose}>
            <CloseIcon />
          </Styled.ModalCloseButton>
          <Youtube url={youtubeEmbeddedCode} />
        </Styled.Modal>
      </Modal>
    </>
  )
}
