import styled, { css, AnyStyledComponent } from 'styled-components'

export const Wrapper: AnyStyledComponent = styled.div`
  ${({ isNotNL, theme }: any) => css`
    width: 100%;
    background-color: ${isNotNL ? theme.palette.secondary.light : '#e5f7ff'};
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
  `}
`

export const Title: AnyStyledComponent = styled.h2`
  ${({ isNotNL }: any) => css`
    color: ${isNotNL ? 'white' : '#1d83ad'};
    padding: 0px;
    font-family: 'Melbourne', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    margin: 0px;
    margin-bottom: 5px;
  `}
`

export const List: AnyStyledComponent = styled.ul`
  ${({ isNotNL }: any) => css`
    margin: 0px;
    padding: 0px;
    width: 100%;
    padding-right: 50px;

    li {
      color: ${isNotNL ? 'white' : '#727272'};
      display: flex;
      flex-direction: row;
      line-height: 22px;
    }
  `}
`

export const Bold = styled.span`
  flex: 0 0 80%;
  font-weight: bold;
  max-width: 80%;
  padding-right: 5px;
`
