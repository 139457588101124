import React, { FC } from 'react'
import { MediaBlockSection, MediaBlockImage } from './styled'
interface MediaBlockProps {
  image: string
}
const MediaBlock: FC<MediaBlockProps> = ({ image }: MediaBlockProps) => {
  return (
    <MediaBlockSection>
      <MediaBlockImage>
        <img src={image} alt="random image b" />
      </MediaBlockImage>
    </MediaBlockSection>
  )
}

export default MediaBlock
