import React from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'

import { IStore } from '@Interfaces'
import { AutoCompleteBox as AutoCompleteBoxBase } from '@Components'
import { SearchBoxActions } from '@Redux/search-box/actions'

interface IAutoCompleteBoxPropsMain {
  ctaButtonText?: string
  searchfieldPlaceholder?: string
}

export const AutoCompleteBox = (props: IAutoCompleteBoxPropsMain) => {
  const dispatch = useDispatch()

  const searchResults = useSelector((state: IStore) => state.searchBox.data)
  const fetchSearchResults = bindActionCreators(SearchBoxActions.FetchData, dispatch)
  const clearSearchResults = bindActionCreators(SearchBoxActions.Reset, dispatch)

  return (
    <AutoCompleteBoxBase
      searchResults={searchResults}
      fetchSearchResults={fetchSearchResults}
      clearSearchResults={clearSearchResults}
      ctaButtonText={props.ctaButtonText}
      searchfieldPlaceholder={props.searchfieldPlaceholder}
    />
  )
}
