import styled, { AnyStyledComponent } from 'styled-components'
import {
  IconButton,
  Button as MuiButton,
} from '@material-ui/core'

export const Modal = styled.div`
  ${({ theme }: any) => `
    background-color: #fff;
    box-shadow: 0 8px 17px 0 rgba(0,0,0,0.1);
    max-width: 500px;
    min-width: 400px;
    min-height: 200px;
    left: 50%;
    outline: 0;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100000;
    padding: 20px;
    border-radius: 5px
    ${theme.breakpoints.up('md')} {
      max-width: 800px;
    }
  `}
`

export const ModalCloseButton = styled(IconButton)`
  color: rgba(255, 255, 255, 0.65);
  top: -50px;
  position: absolute;
  right: -15px;
`

export const Text = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 14px;
`

export const DisclaimerWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`

export const Button: AnyStyledComponent = styled(MuiButton)`
  border-radius: 5px;
  background: #B0D349;
  font-family: 'Melbourne', sans-serif;
  font-size: 24px;
  color: #fff;
  line-height: 24px;
  border: none;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 160px;
  min-height: 34px;
  margin-right: 40px;
  max-width: 170px;
`

export const ChoiceWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`

export const DeclineLink = styled.a`
  color: #727272;
  text-decoration: underline;
`