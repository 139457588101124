import React from 'react'
import getConfig from 'next/config'

import * as Styled from './styled'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

interface INewsletterForm {
  text: string
}

export const NewsletterForm = (props: INewsletterForm) => {
  return (
    <Styled.Wrapper>
      <Styled.Image
        src={`../../../../images/nieuwsbrief_balk${API_SITE === 'FR' ? '_fr' : ''}.png`}
      />
      <div dangerouslySetInnerHTML={{ __html: props.text }}></div>
      <iframe
        src={`../../../../static/Nieuwsbrief.${API_SITE.toLowerCase()}.html`}
        title="Nieuwsbrief"
      />
    </Styled.Wrapper>
  )
}
