import styled, { AnyStyledComponent } from 'styled-components'

export const Wrapper: AnyStyledComponent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 1px;
  padding-right: 1px;
`

export const Image = styled.img`
  display: block;
  height: auto;
  margin-bottom: 5px;
  width: 100%;
`

export const ContentWrapper = styled.div`
  text-align: center;
  overflow: hidden;
  width: 100%;
`

export const Title = styled.h3`
  ${({ theme }) => `
    font-family: 'Melbourne', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: ${theme.palette.primary.main}
    margin: 0 0 5px;

    a {
      color: ${theme.palette.primary.main}
      text-decoration: none;
    }
  `}
`
