import React from 'react'
import { useSelector } from 'react-redux'
import getConfig from 'next/config'
import { getFooterData } from '@Redux/general/selectors'
import { getTranslator } from '@Redux/dictionary/selectors'
import { TranslationKeys, SocialItems } from '@Definitions'

import * as Styled from '@Components/Footer/styled'
import { ISocial, IStore, INavigationItem } from '@Interfaces'
import { CustomLink, Container, Logo } from '@Components'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

const socialItems = SocialItems[API_SITE]

export const Footer = () => {
  const footerData = useSelector((state: IStore) => getFooterData(state.general))
  const t = useSelector((state: IStore) => getTranslator(state.dictionary))
  
  return (
    <Styled.Footer>
      <Container>
        <Styled.InnerFooterWrapper>
          <Styled.LogoWrapper>
            {footerData.logo && footerData.logo.desktopUrl && <Logo logo={footerData.logo} />}
          </Styled.LogoWrapper>
          <Styled.LinkWrapper>
            {footerData.leftMenu && footerData.leftMenu.length > 0 && (
              <ul>
                {footerData.leftMenu.map((item: INavigationItem, i: number) => (
                  <li key={i}>
                    {item.linkType === 'external' || item.linkType === 'prof_switch' ? (
                      <a href={item.url} target={item.openInNewTab ? '_blank' : '_self'}>
                        {item.name}
                      </a>
                    ) : (
                      <CustomLink url={item.url}>{item.name}</CustomLink>
                    )}
                  </li>
                ))}
              </ul>
            )}
            {footerData.firstColumnTitle && (
              <Styled.ProfessionalsColumn>
                <p>{footerData.firstColumnTitle}</p>
                <div dangerouslySetInnerHTML={{ __html: footerData.firstColumnText }} />
              </Styled.ProfessionalsColumn>
            )}
            {footerData.rightMenu && footerData.rightMenu.length > 0 && (
              <ul>
                {footerData.rightMenu.map((item: INavigationItem, i: number) => (
                  <li key={i}>
                    {item.linkType === 'external' || item.linkType === 'prof_switch' ? (
                      <a href={item.url} target={item.openInNewTab ? '_blank' : '_self'}>
                        {item.name}
                      </a>
                    ) : (
                      <CustomLink url={item.url}>{item.name}</CustomLink>
                    )}
                  </li>
                ))}
              </ul>
            )}
            {footerData.secondColumnTitle && (
              <Styled.ProfessionalsColumn>
                <p>{footerData.secondColumnTitle}</p>
                <div dangerouslySetInnerHTML={{ __html: footerData.secondColumnText }} />
              </Styled.ProfessionalsColumn>
            )}
          </Styled.LinkWrapper>
          <Styled.SocialWrapper>
            <p>{t(TranslationKeys.FollowUs)}:</p>
            <p>
              {socialItems &&
                socialItems.map((item: ISocial, i: number) => (
                  <Styled.SocialLink
                    href={item.url}
                    title={item.alt}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={i}
                  >
                    <img height="37px" src={item.image} width="39px" />
                  </Styled.SocialLink>
                ))}
            </p>
          </Styled.SocialWrapper>
		<div id="__ba_panel" className="topRight _launchpad ba-fixed"></div>
        </Styled.InnerFooterWrapper>
        <Styled.CopyrightWrapper>
          <p>
            {t(TranslationKeys.Copyright)} -{' '}
            <CustomLink url={footerData.privacyCookiesLink}>
              {t(TranslationKeys.PrivacyCookies)}
            </CustomLink>
          </p>
          <p>
            {t(TranslationKeys.RealisedBy)}{' '}
            <a
              href="https://www.4net.com"
              rel="noopener noreferrer"
              target="_blank"
              title="4net interactive"
            >
              4net interactive
            </a>
          </p>
        </Styled.CopyrightWrapper>
      </Container>
    </Styled.Footer>
  )
}
