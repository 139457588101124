import React, { useEffect, useState } from 'react'
import getConfig from 'next/config'
import { useSelector } from 'react-redux'
import { getHeaderData } from '@Redux/general/selectors'
import { ScrollMagic } from '@Services'
import { SocialItems } from '@Definitions'

import * as Styled from './styled'

import { IStore } from '@Interfaces'
import { EUBanner, Logo, Menu, ResponsiveDrawer } from '@Components'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

const socialItems = SocialItems[API_SITE]

interface IHeader {
  otherLanguageUrl: string
}

export const Header = ({ otherLanguageUrl }: IHeader) => {
  const [activeMenu, toggleMenu] = useState(false)
  const [hover, setHover] = useState(-1)
  const [activeSubMenu, setActiveSubMenu] = useState(-1)

  const headerData = useSelector((state: IStore) => getHeaderData(state.general))

  // set navigation to fixed
  useEffect(() => {
    const ctrl = new ScrollMagic.Controller()

    if (document.getElementById('fixed-bar')) {
      new ScrollMagic.Scene({
        triggerHook: 0,
        triggerElement: '#fixed-bar',
      })
        .setPin('#fixed-bar')
        .addTo(ctrl)
    }
  }, [])

  const handleMenuClick = () => {
    toggleMenu(!activeMenu)
  }

  const handleActiveSubMenu = (index: number, hover: boolean, subMenu: boolean) => {
    if (hover) {
      setHover(index)

      if (index > -1 && !subMenu) {
        setActiveSubMenu(-1)
      }
    }

    if (subMenu) {
      setActiveSubMenu(index)
    }
  }

  const closeMenu = () => {
    setHover(-1)
    setActiveSubMenu(-1)
  }

  return (
    <Styled.Wrapper>
	  {headerData.leftLogo && headerData.rightLogo && (
        <EUBanner
          leftLogo={headerData.leftLogo}
          leftText={headerData.leftText}
          rightLogo={headerData.rightLogo}
          rightText={headerData.rightText}
        />
	  )}
      <Styled.MenuWrapper id="fixed-bar" role="navigation">
        <Logo logo={headerData.menuBarLogo || headerData.logo} transparent={activeSubMenu > -1} />
        <Styled.DesktopMenu>
          <Menu
            primaryMenuItems={headerData.mainMenu}
            secondaryMenuItems={headerData.topMenu}
            socialItems={socialItems}
            hoverMenuItem={hover}
            activeSubMenu={activeSubMenu}
            setActiveSubMenu={handleActiveSubMenu}
            closeMenu={closeMenu}
            otherLanguageUrl={otherLanguageUrl}
          />
        </Styled.DesktopMenu>
        <Styled.MobileMenu>
          <Styled.HamburgerButton
            onClick={handleMenuClick}
            edge="start"
            color="inherit"
            aria-label="menu"
          >
            <Styled.HamburgerIcon />
          </Styled.HamburgerButton>
        </Styled.MobileMenu>
      </Styled.MenuWrapper>
      <ResponsiveDrawer
        activeMenu={activeMenu}
        primaryMenuItems={headerData.mainMenu}
        secondaryMenuItems={headerData.topMenu}
        socialItems={socialItems}
        toggleMenu={handleMenuClick}
        logo={headerData.menuBarLogo || headerData.logo}
        otherLanguageUrl={otherLanguageUrl}
      />
    </Styled.Wrapper>
  )
}
