import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import Router from 'next/router'
import getConfig from 'next/config'

import { Container } from '@Components'
import { IResponsiveImage, IStore } from '@Interfaces'
import { getRecipeFilters, getRecipeSearchTerm } from '@Redux/recipes/selectors'
import { RecipeActions } from '@Actions'

import * as Styled from './styled'


const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

interface IRecipeSearchProps {
  image?: IResponsiveImage
  bigHero: boolean
  ctaButtonText?: string
  searchfieldPlaceholder?: string
  title?: string
  notTop?: boolean
}

export const RecipeSearch = ({
  image,
  bigHero,
  ctaButtonText,
  searchfieldPlaceholder,
  title,
  notTop,
}: IRecipeSearchProps) => {
  const [value, setValue] = useState('')
  const filtersFromStore = useSelector((state: IStore) => getRecipeFilters(state.recipes))
  const searchTermFromStore = useSelector((state: IStore) => getRecipeSearchTerm(state.recipes))

  const dispatch = useDispatch()

  useEffect(() => {
    setValue(searchTermFromStore)
  }, [searchTermFromStore])

  const onSubmitHandler = () => {
    dispatch(RecipeActions.SetSearchTerm(value))

    // remove empty values
    const filtersString = filtersFromStore
      ? filtersFromStore.filter((val: any) => !!val && val > 0).join(',')
      : ''

    dispatch(RecipeActions.FetchData(value, filtersString, 1))

    let frenchVersion = false
    if (API_SITE === 'FR') {
      frenchVersion = true
    }

    if (Router.asPath.includes('recepten') || Router.asPath.includes('recettes')) {
      let slug = Router.asPath.substr(0, Router.asPath.indexOf('?'))
      window.scrollTo(0, 0)
      Router.push(
        '/[slug]',
        `${slug}?${filtersString ? `&filters=${filtersString}` : ``}&searchTerm=${value}`,
      )
    } else {
      var recepten = "recepten"
      if (frenchVersion) {
        recepten = "recettes"
      }
      window.scrollTo(0, 0)
      Router.push(
        '/[slug]',
        `/${recepten}/?${filtersString ? `&filters=${filtersString}` : ``}&searchTerm=${value}`)
    }
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const onKeyPress = ({ key }: any) => {
    if (key === 'Enter') {
      onSubmitHandler()
    }
  }

  const size = bigHero ? 'large' : 'medium'

  return (
    <Styled.Wrapper
      imageDesktopUrl={image && image.desktopUrl}
      imageMobileUrl={image && image.mobileUrl}
      notTop={notTop}
    >
      <Container>
        <Styled.Inner size={size}>
          <Styled.Title dangerouslySetInnerHTML={{ __html: title }} />
          <Styled.FormWrapper>
            <Styled.Input
              type="text"
              value={value}
              placeholder={searchfieldPlaceholder}
              onChange={onChangeHandler}
              onKeyPress={onKeyPress}
            />
            <Styled.Button type="submit" onClick={onSubmitHandler}>
              {ctaButtonText}
            </Styled.Button>
          </Styled.FormWrapper>
        </Styled.Inner>
      </Container>
    </Styled.Wrapper>
  )
}