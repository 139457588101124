import theme from '@Themes'
import styled, { css } from 'styled-components'

const PageListComponent = styled.section`
  font-family: 'atkinson', serif;
  margin: 0 auto;
  max-width: 1030px;
  ${theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: auto auto;
  }
  ${theme.breakpoints.up('lg')} {
    display: flex;
    flex-wrap: wrap;
  }
`

const PageListItem = styled.article<{
  backgroundColor: string
  secondaryColor: string
  contentOrder: string
  boxWidth: string
  boxHeight: string
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: ${({ contentOrder }) => (contentOrder !== 'child' ? '20px 0' : '20px 0 0')};
  padding: ${({ contentOrder }) =>
    (contentOrder === 'success' && '0px !important') ||
    (contentOrder === 'onzin' && '0px !important') ||
    (contentOrder === 'future' && '0px !important') ||
    (contentOrder === 'sterk' && '0px !important')};
  color: ${({ secondaryColor }) => secondaryColor};
  overflow: hidden;
  position: relative;

  ${theme.breakpoints.up('lg')} {
    flex: ${({ boxWidth }) => boxWidth};
    ${({ contentOrder }) =>
    contentOrder === 'child' &&
    css`
        display: flex;
        justify-content: center;
      `}
  }
  > div {
    display: flex;
    flex-direction: ${({ contentOrder }) =>
    contentOrder !== 'child' ? 'column' : 'column-reverse'};
    align-items: center;
    text-align: center;
    cursor: pointer;
    ${theme.breakpoints.up('lg')} {
      flex-direction: ${({ contentOrder }) =>
    contentOrder !== 'child' ? 'column' : 'row-reverse'};
      ${({ contentOrder }) =>
    contentOrder === 'child' &&
    css`
          padding-left: 20px;
        `}
    }

    ${({ contentOrder }) =>
    (contentOrder === 'tarwe' || contentOrder === 'torso') &&
    css`
        ${theme.breakpoints.up('lg')} {
          // padding-top: 50px;
        }
      `}
    &:not(.noCornerAnim):after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-top: 10px solid transparent;
      border-bottom: 10px solid ${({ secondaryColor }) => secondaryColor};
      border-right: 10px solid ${({ secondaryColor }) => secondaryColor};
      box-shadow: -7px -7px 20px -6px rgba(0, 0, 0, 0.55);
      transition: all 0.25s;
    }
    &:not(.noCornerAnim):before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 10px solid ${({ backgroundColor }) => backgroundColor};
      border-top: 10px solid ${({ backgroundColor }) => backgroundColor};
      border-bottom: 10px solid transparent;
      border-right: 10px solid transparent;
      box-shadow: -7px -7px 20px -8px rgba(0, 0, 0, 0.55);
      transition: all 0.25s;
    }
    &:hover {
      &:after {
        border-left: 20px solid transparent;
        border-top: 20px solid transparent;
        border-bottom: 20px solid ${({ secondaryColor }) => secondaryColor};
        border-right: 20px solid ${({ secondaryColor }) => secondaryColor};
      }
      &:before {
        border-left: 20px solid ${({ backgroundColor }) => backgroundColor};
        border-top: 20px solid ${({ backgroundColor }) => backgroundColor};
        border-bottom: 20px solid transparent;
        border-right: 20px solid transparent;
        box-shadow: -7px -7px 20px -8px rgba(0, 0, 0, 0.55);
      }
    }
  }
  h3 {
    max-width: fit-content;
    margin: 0 auto;
    line-height: 40px;
    max-width: 240px;
    ${({ contentOrder }) =>
    contentOrder === 'tarwe' &&
    css`
        margin-top: -17px;
      `}
    ${theme.breakpoints.up('lg')} {
      max-width: 400px;
    }
  }

  h5 {
    font-size: 16px;
    margin-top: 5px;
    ${({ contentOrder }) =>
    contentOrder === 'child' &&
    css`
        margin: 5px auto 0;
        max-width: 200px;
      `}
    &:before,
    &:after {
      content: '-';
      margin: 0 5px;
    }
  }
`

const PageListVideo = styled.div<{ contentOrder: string }>``

const PageListContent = styled.div<{ contentOrder: string, center: boolean }>`
  padding: 0 10px;
 
  ${({ contentOrder }) =>
    contentOrder === 'tarwe' &&
    css`
      ${theme.breakpoints.up('lg')} {
        margin-right: -240px;
      }
      justify-content: center;
    `}

  ${({center}) => center === true &&
    css`
      height: 100%;
      display: flex;
      align-items:center;
      `}
`

const PageListTitle = styled.h3<{ fontFamily: string; fontSize: string }>`
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize};
`

const PageListImage = styled.div<{
  imageHeight: string
  imagePosistion: string
  contentOrder: string
  cropImageHeight?: string
}>`
  width: 100%;
  height: ${({ imageHeight }) => imageHeight};
  ${theme.breakpoints.up('lg')} {
    ${({ contentOrder }) =>
    contentOrder === 'tarwe' &&
    css`
        display: flex;
        padding: 50px 130px 0 0;

        align-items: center;
      `}
    ${({ contentOrder }) =>
    contentOrder === 'child' &&
    css`
        height: 280px;
        img {
          width: 100%;
        }
      `}
  }
  ${({ contentOrder }) =>
    contentOrder === 'tarwe' &&
    css`
      margin-left: -150px;
    `}
  img {
    max-width: 100%;
    object-fit: fill;
    height: ${({ cropImageHeight }) => cropImageHeight};
    object-position: ${({ imagePosistion }) => imagePosistion};
  }
 
`

export {
  PageListComponent,
  PageListItem,
  PageListTitle,
  PageListImage,
  PageListContent,
  PageListVideo,
}
