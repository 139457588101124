import * as Styled from './styled'
import { IResponsiveImage } from '@Interfaces'

interface IProps {
  leftLogo: IResponsiveImage
  leftText: string
  rightLogo: IResponsiveImage
  rightText: string
}

export const EUBanner = ({ leftLogo, leftText, rightLogo, rightText }: IProps) => {
  return (
    <Styled.Wrapper>
      <Styled.InnerWrapper>
        <Styled.Left>
          {leftLogo && (
            <Styled.EuFlag>
              <img src={leftLogo.desktopUrl} alt="" />
            </Styled.EuFlag>
          )}
          {leftText && <Styled.TextLeft colored dangerouslySetInnerHTML={{ __html: leftText }} />}
        </Styled.Left>
        <Styled.Right>
          {rightText && <Styled.TextRight dangerouslySetInnerHTML={{ __html: rightText }} />}
          {rightLogo && (
            <Styled.Stamp>
              <img src={rightLogo.desktopUrl} alt="" />
            </Styled.Stamp>
          )}
        </Styled.Right>
      </Styled.InnerWrapper>
    </Styled.Wrapper>
  )
}
