import React, { useEffect } from 'react'
import getConfig from 'next/config'
import { useDispatch, useSelector } from 'react-redux'
import Link from 'next/link'
import moment from 'src/local_Packages/moment/moment'
import '../../../local_Packages/moment/locale/nl'
import Router from 'next/router'

import { getHrefOfSlug } from '@Lib'
import { NewsActions } from '@Actions'
import { getNews } from '@Redux/news/selectors'
import { Content, Pagination } from '@Components'
import { IStore, INewsItem } from '@Interfaces'

import * as Styled from './styled'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

interface INewsListProps {
  newsArticles: INewsItem[]
  currentPage: number
  totalPages: number
  objectKind: string
  slug: string
  query?: string
}

export const NewsList = ({
  newsArticles,
  currentPage,
  totalPages,
  slug,
  query,
}: INewsListProps) => {
  if (!newsArticles) return false
  const dispatch = useDispatch()

  const renderItem = (item: INewsItem, index: number) => {
    const { title, publicationDate, intro, url } = item
    const pubDate = moment(publicationDate)

    return (
      <Styled.ListItem key={index}>
        <Styled.ListItemTitle site={API_SITE} component="h2" variant="h2">
          <Link href={getHrefOfSlug(url)} as={url}>
            <a>{title}</a>
          </Link>
        </Styled.ListItemTitle>

        <Content>
          {pubDate && (
            <Styled.ListItemDate dateTime={pubDate.format('YYYY-MM-DD')}>
              {pubDate.format('D MMM YYYY')}
            </Styled.ListItemDate>
          )}
          {` - ${intro}`}
        </Content>
      </Styled.ListItem>
    )
  }

  const onPageChange = (page: number) => {
    dispatch(NewsActions.FetchData(page))

    Router.push('/[slug]', `${slug}?pageNumber=${page}`, { shallow: true });
  }

  useEffect(() => {
    // get items based on query
    if (query) {
      const params = new URLSearchParams(query)
      const paramPage = params ? params.get('pageNumber') : 0
      const page = paramPage ? parseInt(paramPage) : 0
      if (page) {
        onPageChange(page)
      }
    }
  }, [])

  const newsByPaging = useSelector((state: IStore) => getNews(state.news))
  let itemsToShow = newsArticles,
    totalPagesToShow = totalPages,
    currentPageToShow = currentPage

  if (newsByPaging && newsByPaging.totalPages) {
    itemsToShow = newsByPaging.newsArticles
    totalPagesToShow = newsByPaging.totalPages
    currentPageToShow = newsByPaging.currentPage
  }

  return (
    <section>
      <Styled.List>{itemsToShow && itemsToShow.map((item, i) => renderItem(item, i))}</Styled.List>

      <Pagination
        totalPage={totalPagesToShow}
        currentPage={currentPageToShow}
        onChange={onPageChange}
      />
    </section>
  )
}
