import theme from '@Themes'
import styled from 'styled-components'

const ContentBannerComponent = styled.section<{ secondaryColor: string; backgroundColor: string, image?: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ secondaryColor }) => secondaryColor};
  position: relative;
  max-width: 1030px;
  margin: 0 auto;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    ${theme.breakpoints.up('md')} {
      max-width: 1030px;
      flex-direction: row;
      margin: 0 auto;
    }
    figure {
      // max-width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 300px;
      width: 100%;
      overflow: hidden;
      margin: 0;
      ${theme.breakpoints.up('md')} {
        min-width: ${({ image }) => (image !== 'travelmedia' ? '' : '500px')};
      }
    }
    img {
      width: 100%;
      object-fit: fill;
      object-position: center center;
    }
  }
  p {
    font-size: 16px;
    padding: 40px 40px 60px;
  }
  a {
    color: ${({ secondaryColor }) => secondaryColor};
    text-decoration: none;
    font-weight: bold;
  }
  ${theme.breakpoints.up('md')} {
    p {
      padding: 40px 60px;
    }
  }
`
const ContentBannerIcon = styled.span`
  position: absolute;
  display: block;
  left: 0;
  bottom: 30px;
  ${theme.breakpoints.up('sm')} {
    display: none;
  }
`
export { ContentBannerComponent, ContentBannerIcon }
