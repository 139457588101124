import React, { FC } from 'react'
import { FooterBannerComponent, FooterBannerContent, FooterBannerImageLeft } from './styled'

interface FooterBannerProps {
  title: string
  paragraph: string
  url: string
  style?: object
}

const FooterBanner: FC<FooterBannerProps> = ({
  title,
  paragraph,
  url,
  style,
}: FooterBannerProps) => {
  return (
    <FooterBannerComponent style={style}>
      <a href={url} target="_black">
        <FooterBannerContent>
          <FooterBannerImageLeft>
            <img src="/images/landingpage/footerbannerBrood.png" />
          </FooterBannerImageLeft>
          <div>
            <h2>{title}</h2>
            <h5>{paragraph}</h5>
          </div>
          <img src="/images/landingpage/footerbannerBrood.png" />
        </FooterBannerContent>
      </a>
    </FooterBannerComponent>
  )
}

export default FooterBanner
