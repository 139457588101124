import styled, { AnyStyledComponent } from 'styled-components'

export const Wrapper: AnyStyledComponent = styled.div`
  ${({ dimension }: any) => `
    background-size:cover;
    background-position: top;
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
    
    &::before {
      display: block;
      content: "";
      
      padding-top: ${dimension === '3x4' ? '75%': '56.25%'};
    }
    
    iframe,
    embed,
    object,
    video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  `}
`