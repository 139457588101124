import React from 'react'
import Link from 'next/link'
import getConfig from 'next/config'

import * as Styled from './styled'

interface IFAQCategories {
  categories: ICategory[]
}

interface ICategory {
  name?: string
  url: string
}

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

export const FAQCategories = (props: IFAQCategories) => {
  const isNotNL = ['BE', 'FR'].indexOf(API_SITE) > -1

  return (
    <Styled.Wrapper isNotNL={isNotNL}>
      <nav>
        <Styled.List>
          {props &&
            props.categories.map((category: ICategory, i) => (
              <Styled.ListItem key={i}>
                <Link href={category.url} passHref>
                  <a>{category.name}</a>
                </Link>
              </Styled.ListItem>
            ))}
        </Styled.List>
      </nav>
    </Styled.Wrapper>
  )
}
