import styled from 'styled-components'

export const Wrapper = styled.div`
  color: #979797;
  font-weight: 300;
  font-size: 12px;
  align-items: center;
  line-height: 24px;
  padding-top: 20px;
  margin-bottom: 20px;

  a {
    color: #979797;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const Arrow = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-top: -6px;

  &:before {
    color: #979797;
    content: '›';
    display: inline-block;
    font-family: 'Helvetica Neue', 'Arial', sans-serif;
    font-size: 18px;
    text-align: center;
    vertical-align: middle;
    width: 16px;
  }
`
