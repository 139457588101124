import React, { useEffect, useRef } from 'react'
import { defineMessages, IntlProvider as IntlProviderBase } from 'react-intl'

import { IIntlProvider } from '@Interfaces'

export const IntlProvider = ({
  children,
  dictionary,
  locale,
  fetchData,
}: IIntlProvider.TCombinedProps) => {
  // To see whether locale actually changes,
  // we compare it with the previous value
  const previousLocale = usePrevious(locale)

  // Fetch new dictionary when locale changes
  useEffect(() => {
    if (locale !== previousLocale) {
      fetchData()
    }
  }, [locale])

  // Set new messages
  useEffect(() => {
    defineMessages(dictionary)
  }, [dictionary])

  return (
    <IntlProviderBase
      key={locale} // needed to force rerender when locale changes
      locale={locale}
      messages={dictionary}
      textComponent={React.Fragment} // to prevent <span> around translated text
    >
      {children}
    </IntlProviderBase>
  )
}

const usePrevious = (value: string) => {
  const ref = useRef<string>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
