import styled, { AnyStyledComponent } from 'styled-components'
import { Typography } from '@material-ui/core'

export const Wrapper = styled.div`
  padding-bottom: 40px;
  padding-top: 64px;
`

export const Inner = styled.div`
  padding: 0 10px;
`

export const Category = styled.span`
  background-color: #a1a1a1;
  border-radius: 12px;
  color: #fff;
  font-family: 'Melbourne', sans-serif;
  font-size: 12px;
  padding: 2px 10px;
  margin-right: 5px;
`
export const Title: AnyStyledComponent = styled(Typography)`
  margin-bottom: 10px;
`

export const List = styled.ul`
  list-style: none outside;
  margin-bottom: 0;
  padding-left: 0;
`

export const ListItem = styled.li`
  margin-bottom: 20px;
`
export const ListItemTitle: AnyStyledComponent = styled(Typography)`
  ${({ theme, site }: any) => `
    margin-bottom: 0;

    a {
      color: ${
        ['BE', 'FR'].indexOf(site) > -1
          ? theme.palette.secondary.light
          : theme.palette.primary.light
      };
      text-decoration: none;
    }
  `}
`

export const ListItemText = styled.p`
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0px;
`

export const ListItemQuote = styled.blockquote`
  color: #999;
  font-style: italic;
  margin: 0;
  padding: 0;
`
export const Description = styled.p`
`