import React from 'react'
import * as Styled from './styled'

interface IProps {
  code?: string
  dimension?: '3x4' | '9x16'
  url?: string
}

export const Youtube = ({ dimension = '9x16', url, code }: IProps) => {
  let videoId = ''

  // get the youtube ID from 2 address or share url
  if (code) {
    videoId = code
  } else if (url) {
    const urlResult = url.match('v=([a-zA-Z0-9_]+)&?')
    const shareResult = url.match('youtu.be/([a-zA-Z0-9_]+)')

    if (urlResult && urlResult.length > 1) {
      videoId = urlResult[1]
    } else if (shareResult && shareResult.length > 1) {
      videoId = shareResult[1]
    }
  }
  if (videoId) {
    const embedCode = `<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/${videoId}?rel=0&controls=1&showinfo=0&origin=https:%2F%2Fwww.brood.net" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
    return (
      <Styled.Wrapper
        dimension={dimension}
        dangerouslySetInnerHTML={{ __html: embedCode }}
      ></Styled.Wrapper>
    )
  } else {
    return <div>De opgegeven youtube url heeft niet het juiste format.</div>
  }
}
